@import 'logos-slider';

.logos-slider {
  .splide__list {
    .splide__slide {
      img {
        max-width: 100px;
        max-height: 30px;
      }
    }
  }
}
