.big-title-1 {
  @extend .vanitas-bold;
  font-size: 5.5rem;
}

.keyword {
  @extend .vanitas-bold;
  font-size: 3rem;
  text-transform: uppercase;
}

.big-number {
  @extend .vanitas-bold;
  font-size: 8rem;
  text-transform: uppercase;
}

.title-1 {
  @extend .montserrat-ultra-light;
  font-size: 1.9rem;
  line-height: 1.9rem;
}

.title-2 {
  @extend .montserrat-light;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.title-3 {
  @extend .montserrat-light;
  font-size: 1.75rem;
  text-transform: uppercase;
}

.title-4 {
  @extend .montserrat-light;
  font-size: 1.2rem;
}

.title-5 {
  @extend .montserrat-light;
  font-size: 1.1rem;
}

.text-1 {
  @extend .rawline-regular;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.text-2 {
  @extend .rawline-regular;
  font-size: 1.2rem;
  line-height: 0.75rem;
}

.text-3 {
  @extend .rawline-regular;
  font-size: 1.2rem;
}

.text-strong {
  font-weight: bold;
}

.nav-1 {
  @extend .rawline-bold;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.nav-3 {
  @extend .rawline-bold;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.hat-1 {
  @extend .montserrat-light;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.hat-2 {
  @extend .rawline-bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.hat-3 {
  @extend .rawline-regular;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.hat-4 {
  @extend .rawline-regular;
  font-size: 1rem;
  text-transform: uppercase;
}

.basket-1 {
  @extend .rawline-semi-bold;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.basket-2 {
  @extend .rawline-semi-bold;
  font-size: 0.75rem;
}

.basket-3 {
  @extend .rawline-semi-bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.basket-4 {
  @extend .rawline-semi-bold;
  font-size: 2rem;
}

.basket-5 {
  @extend .rawline-bold;
  font-size: 0.9rem;
}

.filter-1 {
  @extend .rawline-regular;
  font-size: 0.75rem;
}

.filter-1-5 {
  @extend .rawline-regular;
  font-size: 0.9rem;
}

.filter-2 {
  @extend .rawline-regular;
  font-size: 1rem;
}

.price-sheet {
  @extend .rawline-semi-bold;
  font-size: 1.3rem;
  white-space: nowrap;
  small {
    font-size: 1.1rem;
    font-weight: normal;
  }
}

.cta-1 {
  @extend .montserrat-regular;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.cta-2 {
  @extend .montserrat-light;
  font-size: 1rem;
  text-transform: uppercase;
}

.cta-2-5 {
  @extend .montserrat-light;
  font-size: 1rem;
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

ul.dotted-list {
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
}
