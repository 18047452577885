@import 'selection-modal';

.selection-modal-manager {
  .selection-modal {
    max-width: 80%;

    .close-button {
      font-size: 1.5rem;
      top: 15px;
      right: 15px;
      padding: 0;
    }

    .visual {
      display: none;
    }

    .full {
      padding: 24px;
    }

    .selection-modal-content {
      .content {
        .cta {
          display: block;
          margin: 0 0 15px;
          width: 100%;
        }
        label {
          color: $black;
        }
        .input-wrapper {
          input {
            appearance: none;
            border-radius: 0;
            padding: 1px 0;
          }
          .input-suffix {
            right: 14px;
          }
        }
      }
    }

    .functional {
      padding: 24px;
      width: 100%;
      .content {
        .title-2 {
          &:after {
            margin-bottom: 25px;
          }
        }
      }
    }

    input.quantity-input {
      font-size: 2.5rem;
      width: 60px;
      padding: 4px 0;
    }

    &.add-place-modal {
      ul {
        padding-left: 0;
      }
      label {
        @extend .text-1;
      }
    }

    &.add-menu-modal {
      .form-actions {
        margin-bottom: 0;
        margin-top: 20px;
        button {
          display: inline-block;
          margin: 10px auto 0;
        }
      }
    }

    &.merge-selections-modal {
      .content {
        .cta {
          font-size: 10px;
        }
      }
    }
  }
}

// individual modals
@import 'brief-modal/brief-modal.mobile';
