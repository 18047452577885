@import 'event';

.page.event {
  .form-row-rent {
    margin-bottom: 80px;
    padding: 0 6%;
    text-align: center;

    #formulaire {
      position: relative;
      top: -80px;
    }

    .form-row-rent-title {
      color: $yellow;
      margin: 60px auto 40px;
      display: block;
      font-size: 30px;
    }

    .form-row-rent-content {
      .form-row-rent-content-left-column {
        margin-bottom: 40px;
        vertical-align: top;
        .button-wrapper {
          margin-top: 20px;
        }
      }

      .form-row-rent-content-right-column {
        vertical-align: top;
      }
    }
  }

  .event-wrapper {
    padding: 0 6%;

    .subtitle {
      margin-bottom: 21px;
    }

    .event-pattern {
      position: relative;
      background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_01.jpg');
      width: 100%;
      margin: -65px 0 0;
      z-index: 12;
      text-align: center;
      color: $white;
      height: 190px;

      .pattern-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 270px;

        .text-wrapper-event {
          margin-bottom: 15px;
        }

        .text-event-pattern {
          position: relative;
        }

        .icon-wrapper-event {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 10px;
          margin: 0 auto;

          .icon-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transition: bottom 0.4s $ease-out-cubic;
            animation: arrow-down-move 1.5s infinite linear alternate;

            .header-arrow-down-sup {
              display: inline-flex;
              align-items: center;
              background: transparent;
              .icon {
                width: 9px;
                height: 7px;
                fill: $white;
                color: $white;
              }
            }

            .header-arrow-down-sub {
              display: inline-flex;
              align-items: center;
              background: transparent;
              .icon {
                width: 9px;
                height: 7px;
                fill: $white;
                color: $white;
              }
            }
          }
          @keyframes arrow-down-move {
            0% {
              bottom: -30px;
            }
            15% {
              bottom: -35px;
            }
            30% {
              bottom: -30px;
            }
          }
        }
      }
    }
  }
  .ct--breadcrumb {
    margin-bottom: 65px;
  }

  .button-wrapper {
    text-align: center;
    margin-bottom: 60px;
  }

  .our-places {
    padding-bottom: 60px;

    .components-wrapper {
      padding: 10px 6%;
    }

    .title-our-places {
      text-align: center;
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .ct--double-col-slider {
      margin-bottom: 42px;
      margin-top: -40px;
      &.right {
        .container {
          .cols {
            .col-left:after {
              display: none;
            }
          }
        }
      }
      .container {
        .cols {
          .col-left {
            position: relative;
            margin-top: 40px;

            .content-double-col {
              margin: 20px 0;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: -40px;
              left: 50%;
              transform: translateX(-50%);
              width: 175px;
              height: 22px;
              background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
            }
          }
        }
      }
    }

    .button-our-places {
      text-align: center;
    }

    .ct.ct--double-col-slider .container .cols .col.col-picture .slider:before {
      padding-bottom: 70%;
    }

    .ct.ct--double-col-slider .container .cols .col.col-picture .slider .inner {
      img {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  .our-benefits {
    padding-top: 45px;
    padding-bottom: 1px;
    background-color: $light-beige;
    text-align: center;
    .title-our-benefits {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    .components-wrapper {
      padding: 10px 6%;
    }

    .subtitle-our-benefits {
      margin-bottom: 26px;
      display: inline-block;
      max-width: 300px;
    }

    .description-our-benefits {
      margin-top: -10px;
      margin-bottom: 30px;
      padding: 0 6%;
    }

    .components-wrapper {
      padding: 0 6%;
      margin-bottom: 65px;

      .ct--item-result {
        text-align: left;

        &:first-child {
          margin-bottom: 18px;
        }
      }

      .text-1 h2 {
        margin-bottom: 10px;
      }
    }

    .ct.ct--item-result.place .pictures,
    .ct.ct--item-result.menu .pictures,
    .ct.ct--item-result.cocktail .pictures {
      img {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  .faq-row {
    margin: 50px auto 25px;
    .landing-section-faq {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  .new-service {
    padding-top: 47px;
    padding-bottom: 71px;
  }
}
