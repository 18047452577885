@import 'slider-item';

.ct.ct--slider-item {
  .bullets {
    bottom: 10px;
    li {
      width: 5px;
      height: 5px;
    }
  }
  .ui-slider-item {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
    .btn-slider-item {
      font-size: 0.8rem;
      padding: 5px 8px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}
