@import 'content-block';

.content-block {
  margin-bottom: 10px;

  .block-header {
    margin-bottom: 30px;
    padding: 0 20px;
    font-size: 3rem;
  }

  .block-content {
    .block-content-content {
      padding: 0 20px;

      h2,
      h3 {
        margin-bottom: 20px;
      }

      h4 {
        margin-bottom: 8px;
        font-size: 1.6rem;
      }

      p {
        margin-bottom: 20px;
      }

      p + ul {
        margin-top: -10px;
      }

      .cta-wrapper {
        margin-top: 30px;

        &.multiple-ctas {
          .ct--button {
            margin-bottom: 25px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.text-image-block,
  &.text-place-block,
  &.text-video-block,
  &.text-form-block,
  &.text-contact-form-block {
    .block-content {
      display: block;

      .block-content-content {
        display: block;
        margin-top: 30px;
      }
    }
  }

  &.text-image-block {
    .block-content {
      .block-content-image {
        .block-content-image-wrapper {
          width: unset;
          height: unset;
          aspect-ratio: 16/9;
        }
      }
    }

    &.with-image-pattern {
      .block-content-content {
        margin-top: 40px;
      }

      .block-content-image-wrapper {
        &:after {
          bottom: -15px;
          left: 0;
        }
      }

      &.text-image-right-block {
        .block-content-image-wrapper {
          &:after {
            bottom: -15px;
            right: 0;
          }
        }
      }
    }
  }

  &.text-place-block {
    .block-content {
      .block-content-place {
        .ct--item-result {
          margin-bottom: 0;

          &:after {
            display: none;
          }
        }
      }

      .block-content-content {
        margin-top: 15px;
      }
    }
  }

  &.text-trends-block {
    .section-trends {
      margin: 30px 0;
    }
  }

  &.text-form-block {
    padding: 0;

    #formulaire {
      top: -80px;
    }

    .logos-slider {
      margin: 30px 0 20px;
    }

    .block-content {
      .block-content-content {
        .block-content-content-wrapper {
          h2 {
            margin-bottom: 30px;
          }
        }
      }

      .block-content-form {
        padding: 10px 20px 0;

        .hs-form {
          box-shadow: none;
          padding: 0;
        }
      }
    }
  }

  &.text-contact-form-block {
    padding: 0;

    #formulaire {
      top: -80px;
    }

    .logos-slider {
      margin: 30px 0 20px;
    }

    .block-content {
      .block-content-content {
        .block-content-content-wrapper {
          h2 {
            margin-bottom: 30px;
          }
        }
      }

      .block-content-form {
        padding: 0;

        position: relative;

        %pattern {
          background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
            center center repeat;
          content: '';
          height: 66%;
          position: absolute;
          width: 66%;
          z-index: 1;
        }

        &:before {
          @extend %pattern;
          height: 100%;
          width: 80%;
          right: 10%;
          top: -25px;
          left: 10%;
        }

        .contact-form {
          padding: 20px;
          z-index: 2;

          .form-contact-place {
            label,
            .form-control {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  &.text-collection-block {
    .block-content-collection {
      display: block;

      .block-content-collection-row {
        display: block;

        > .ct {
          width: unset;
          margin: unset;

          &:after {
            display: none;
          }

          .about,
          .content {
            text-align: left;
          }
        }
      }

      .cta-wrapper {
        margin-top: 20px;
      }
    }
  }

  // common options

  &.bg-color-emphasis {
    padding: 30px 0 40px;

    &.no-margin-bottom {
      padding-bottom: 10px;
    }
  }
}
