.page.homepage-index {
  .section-filter-home {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 2;
    color: $white;

    .container {
      padding: 0 7%;
      text-align: center;

      .inner {
        background: #1f5f73
          url('../../../../../../web/medias/img/generics/pattern/se_pattern_01.jpg')
          repeat center center;
        background-size: 50% auto;
        margin: 0 auto;
        padding: 25px 7% 30px;

        .more-home {
          p {
            font-size: 1.5rem;
            line-height: 1.25;
            margin: 0 0 20px;
          }

          .ct.ct--button.arrow {
            width: 100%;

            span {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
