@import '../../variables/colors';
@import './search';

.search-main {
  padding-top: 50px; // navbar
  &.show {
    padding-top: 50px;
  }
  .search-wrapper {
    min-height: 500px;
    .search-result {
      .landing-section {
        .text-justify {
          text-align: justify;
        }
      }
      .last-news {
        .news-wrapper {
          flex-wrap: wrap;
          .ct--news-item {
            width: 100%;
            margin: 0 0 15px;
          }
          .about {
            padding: 10px;
            .title-news {
              font-size: 16px;
            }
          }
        }
      }
      .section-snapmag {
        padding-left: 25px;
        padding-right: 25px;
      }
      .search-list {
        &.no-filters {
          margin-top: 0;
          .filters-bar {
            min-height: 0;
            display: none;
          }
          .sorts-bar {
            min-height: 0;
            display: none;
          }
        }
        .landing-result-intro {
          padding-left: 25px;
          padding-right: 25px;
        }
        .search-no-result {
          padding: 7%;
          .search-no-result-left {
            margin-bottom: 40px;
            text-align: center;
            .title-1 {
              margin-bottom: 20px;
            }
            .text-1 {
              line-height: 1.5;
              margin-bottom: 20px;
            }
          }
          .search-no-result-right {
            text-align: center;
            img {
              display: none;
              max-width: 80%;
            }
          }
        }
        .no-results {
          padding: 7%;
          @extend .rawline-regular;
          font-size: 1.4rem;
          text-align: center;
          width: 100%;
        }
        .all-results {
          padding: 0;
        }
        .pagination {
          @extend .rawline-regular;
          margin-top: 45px;
          font-size: 1.3rem;
          .pages-numbers {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              width: 35px;
              height: 35px;
              border: 1px solid $medium-beige;
              margin: 0 1px;
              &.dots {
                border: none;
              }
              &.page-next,
              &.page-prev {
                .icon {
                  width: 5px;
                  height: 8px;
                }
              }
              &.page-prev {
                .icon {
                  transform: rotate(180deg);
                }
              }
              &.current {
                background: $medium-beige;
              }
              a,
              span {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $black;
              }
            }
          }
          .about-results {
            margin: 20px 0;
            text-align: center;
          }
        }
        .filters-bar {
          height: 50px;
          width: 100%;
          border-bottom: 1px solid $breadcrumb;
          margin-bottom: 15px;
          overflow: visible;
          overflow-x: visible;

          &:after {
            content: ' ';
            right: 0;
            top: 0;
            position: absolute;
            z-index: 10;
            background: -moz-linear-gradient(
              left,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.75) 50%,
              rgba(255, 255, 255, 1) 100%
            );
            background: -webkit-linear-gradient(
              left,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.75) 50%,
              rgba(255, 255, 255, 1) 100%
            );
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.75) 50%,
              rgba(255, 255, 255, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
            height: 100%;
            width: 50px;
            display: block;
            pointer-events: none;
          }

          .filters {
            display: flex;
            align-items: center;
            height: 100%;
            overflow-x: scroll !important;
            padding-right: 50px;

            .left-content,
            .right-content {
              display: flex;
              padding-left: 15px;
              align-items: center;
              height: 100%;
              .filter {
                position: relative;
                margin-right: 15px;
                // height: 25px;
                // line-height: 25px;
                &.filter-type-link {
                  width: 200px;
                  .ct--select-checkbox,
                  .ct--select-checkbox-multiple,
                  .ct--select-slider {
                    width: 200px;
                  }
                }
                &:nth-last-child(1) {
                  margin-right: 0;
                }
                // .filter-select-choice {
                // 	display: none;
                // }
                .ct--select-checkbox,
                .ct--select-checkbox-multiple,
                .ct--select-slider {
                  width: 120px;
                  height: 25px;
                  .label {
                    max-width: 80%;
                    text-transform: initial;
                    //font-size: 1.2rem;
                  }
                  .filter-choice {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 25px;
                    .response-filter {
                      line-height: 25px;
                    }
                  }

                  .range-slider {
                    margin: 20px 0;
                  }

                  .filter-select-choice {
                    position: absolute;
                    top: 25px;
                    .generic-label {
                      display: none;
                    }
                  }
                  .all-choices,
                  .select-choice {
                    padding: 10px 15px;
                  }
                }
                .btn-filter {
                  position: relative;
                  border: 1px solid $medium-beige;
                  // height: 25px;
                  color: $black;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 9px 15px;
                  align-items: stretch;
                  justify-content: space-between;
                  background: $white;
                  font-family: $rawline;
                  font-size: 1.2rem;
                  white-space: nowrap;
                  &.active {
                    background: $medium-beige;
                  }
                  .icon {
                    width: 13px;
                    height: 14px;
                  }
                  &.date-hour,
                  &.all-filters {
                    min-width: 90px;
                    .icon {
                      margin-right: 10px;
                    }
                  }
                  &.date-hour {
                    width: 145px;
                  }
                  .filter-quantity {
                    @extend .rawline-bold;
                    font-size: 0.9rem;
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    right: -5px;
                    top: -5px;
                    background: $light-duck;
                    color: $white;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    line-height: 1px;
                    &.show {
                      display: flex;
                    }
                  }
                }
              }
            }
            .right-content {
              padding-right: 35px;
            }

            .popin-filter-date,
            .popin-filter-search,
            .popin-all-filters {
              padding-bottom: 110px !important;
              .button-container-submit {
                position: fixed;
                width: 100%;
                background: $light-beige;
                bottom: 0;
                left: 0;
                text-align: center;
                z-index: 10;
                padding: 15px 30px;
                box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.1);
                a {
                  text-align: center;
                  margin: 0;
                  width: 100%;
                  display: block;
                  line-height: 45px;
                  span {
                    display: inline;
                  }
                }
              }
            }

            .popin-filter-date {
              position: fixed;
              right: 0;
              top: 100px;
              width: 100vw;
              height: calc(100vh - 100px);
              overflow-y: auto;
              z-index: 60;
              background: $white;
              display: none;
              padding: 50px 30px 30px;
              // display: flex;
              border-right: 1px solid $dark-beige;
              border-left: 1px solid $dark-beige;
              border-bottom: 1px solid $dark-beige;

              &.show {
                display: block;
              }
              .generic-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
              }
              .filter {
                height: auto;
                .ct--select-checkbox,
                .ct--select-checkbox-multiple,
                .ct--select-slider {
                  &.open {
                    height: auto;
                  }
                }
                .filter-select-choice {
                  position: relative;
                  top: auto;
                }
              }
              .col-date {
                // padding-right: 30px;
                margin-bottom: 30px;
                .calendar-filter-date {
                  display: none;
                  width: 235px;
                }
                .flatpickr-calendar.inline {
                  display: block;
                  position: relative;
                  /* top: 2px; */
                  margin: 0 auto;
                }
              }
              .col-selects {
                padding-bottom: 50px;
                .generic-label {
                  display: block !important;
                  margin-bottom: 10px;
                  color: $yellow;
                  font-size: 1.2rem;
                  font-family: $rawline;
                  font-weight: bold;
                  text-transform: uppercase;
                }
                .ct--filter {
                  width: 100%;
                  height: auto;
                  .filter-choice {
                    position: relative;
                    height: 35px;
                    background: $medium-beige;
                  }
                  .select-choice,
                  .head-choice {
                    background: $medium-beige;
                    padding: 8px 15px;
                    line-height: initial;
                    .label {
                      max-width: 100%;
                    }
                  }
                }
                .line-from-to {
                  position: relative;
                  display: flex;
                  margin-top: 30px;
                  &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $dark-beige;
                    left: 0;
                    top: -15px;
                  }
                  .col {
                    width: calc(50% - 5px);
                    &:nth-child(1) {
                      margin-right: 10px;
                    }
                  }
                }
              }
              .underline {
                display: inline-block;
                text-align: center;
                color: $black;
                text-decoration: underline;
                margin: 20px auto;
                display: block;
                line-height: 1.3rem;
              }
              .btn-submit-date {
                justify-content: center;
                width: 100%;
                margin: 20px 0;
              }
            }
            .popin-filter-search {
              position: fixed;
              right: 0;
              top: 100px;
              width: 100vw;
              height: calc(100vh - 100px);
              overflow-y: auto;
              z-index: 60;
              background: $white;
              display: none;
              padding: 50px 30px 30px;
              border-right: 1px solid $dark-beige;
              border-left: 1px solid $dark-beige;
              border-bottom: 1px solid $dark-beige;
              // display: block;
              &.show {
                display: block;
              }
              .filter {
                height: auto;
              }
              .btn-submit-search {
                justify-content: center;
                width: 100%;
                margin: 20px 0 0;
              }
            }
            .popin-all-filters {
              position: fixed;
              right: 0;
              top: 100px;
              width: 100vw;
              height: calc(100vh - 100px);
              overflow-y: auto;
              z-index: 60;
              display: none;
              // height: 356px;
              background: $white;
              padding: 50px 30px 30px;
              padding-bottom: 50px;
              border-right: 1px solid $dark-beige;
              border-left: 1px solid $dark-beige;
              border-bottom: 1px solid $dark-beige;
              .underline {
                display: block;
                margin-top: 20px;
                @extend .rawline-regular;
                font-size: 1.3rem;
                text-align: center;
                color: $black;
                text-decoration: underline;
              }
              .generic-label {
                display: block !important;
                margin-bottom: 10px;
                color: $yellow;
                font-size: 1.2rem;
                font-family: $rawline;
                font-weight: bold;
                text-transform: uppercase;
              }
              .filter {
                height: auto;
                position: relative;
                margin-bottom: 20px;
                margin-right: 0;
                &.hidden {
                  display: none;
                }
                &.range-filter {
                  &:before {
                    display: none;
                  }
                }
                .ct--select-checkbox,
                .ct--select-checkbox-multiple,
                .ct--select-slider {
                  &.open {
                    height: auto;
                  }
                }
                .filter-select-choice {
                  position: relative;
                  top: auto;
                }
                //&:before {
                //	position: absolute;
                //	content: '';
                //	width: 100%;
                //	height: 1px;
                //	left: 0;
                //	bottom: -20px;
                //	background: $dark-beige;
                //}
                .ct--filter {
                  width: 100%;
                  height: auto;
                  .filter-choice {
                    position: relative;
                    height: 35px;
                    background: $medium-beige;
                  }
                  .select-choice,
                  .head-choice {
                    background: $medium-beige;
                    padding: 8px 15px;
                    line-height: initial;
                    .label {
                      max-width: 100%;
                    }
                  }

                  .head-choice {
                    padding: 0 15px;
                  }
                }
              }
              .btn-submit-all-filters {
                justify-content: center;
                width: 100%;
                margin: 20px 0;
              }
              &.show {
                display: block;
              }
            }
          }
        }
        .ct--breadcrumb {
          margin-bottom: 20px;
        }
        .ct--breadcrumb .inner,
        .title-page-result,
        .sorts-bar {
          padding: 0 7%;
        }
        .title-page-result {
          margin-bottom: 10px;
        }
        .sorts-bar {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .result-number {
            font-size: 1.1rem;
          }
          .generic-label {
            display: none;
          }
        }
      }
    }
    .search-sidebar {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 250;
      background: $white;
      &:before {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(55, 55, 55, 0.5);
        transition: opacity 0.4s $ease-out-cubic,
          visibility 0.4s $ease-out-cubic;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
      }
      &.basket-submenu-open {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
      &.show {
        display: block;
      }
      > .headline {
        position: relative;
        z-index: 10;
        background: $medium-beige;
        height: 65px;
        width: 100%;
        .close-basket {
          position: absolute;
          top: 15px;
          left: 30px;
          width: 40px;
          height: 40px;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 15px;
            width: 15px;
            fill: $black;
            color: $black;
          }
        }
        > ul {
          display: flex;
          justify-content: space-around;
          height: 100%;
          li {
            display: block;
            //position: relative;
            display: flex;
            text-transform: uppercase;
            height: 100%;
            align-items: center;

            &:before {
              position: absolute;
              content: '';
              width: 0;
              height: 0;
              bottom: -8px;
              left: 50%;
              margin-left: -7px;
              border-style: solid;
              border-width: 8px 7.5px 0;
              border-color: $medium-beige transparent transparent transparent;
              display: none;
            }
            &.active {
              &:before {
                display: block;
              }
            }
            &.your-demand {
              span {
                position: relative;
                font-size: 1.8rem;
                font-family: $rawline;
                font-weight: normal;
                font-style: normal;
                .quantity {
                  @extend .rawline-bold;
                  font-size: 0.9rem;
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                  right: -25px;
                  top: -10px;
                  background: $light-duck;
                  color: $white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
              .arrow {
                position: absolute;
                top: 27px;
                right: 20px;
                transform-origin: center;
                transition: transform 0.4s $ease-out-cubic;
                &.is-open {
                  transform: rotate(180deg);
                }
                svg {
                  height: 10px;
                  width: 10px;
                  fill: $medium-grey;
                }
              }
              .basket-options-dropdown {
                //display: none;
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: $medium-beige;
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.5s $ease-out-cubic;
                .basket-option {
                  position: relative;
                  display: block;
                  height: 65px;
                  line-height: 65px;
                  padding: 0 25px;

                  &:first-child {
                    .basket-option-label {
                      border-top: 1px solid $dark-beige;
                    }
                  }
                  &:last-child {
                    .basket-option-label {
                      border-bottom: none;
                    }
                  }
                  .basket-option-label {
                    display: block;
                    font-size: 1.3rem;
                    font-family: $rawline;
                    text-transform: none;
                    color: $medium-grey;
                    border-bottom: 1px solid $dark-beige;
                  }
                  .icon {
                    position: absolute;
                    right: 25px;
                    top: 20px;
                    width: 20px;
                    height: 20px;
                    fill: $medium-grey;
                    color: $medium-grey;
                  }
                  svg {
                    fill: $medium-grey;
                  }
                }
              }
            }
          }
        }
      }
      .response-sidebar {
        height: calc(100% - 65px);
        .card {
          // display: none;
          .map-container {
            width: 100%;
            height: 535px;
          }
        }
        .filter-items {
          padding: 25px;
          height: calc(100% - 155px);
          overflow-y: auto;
          background: #fff8f0;
          .filter-item {
            margin-bottom: 15px;
            a {
              color: $black;
              &.underline {
                text-decoration: underline;
              }
            }
            &.center {
              text-align: center;
            }
            .title-sidebar-filter {
              @extend .rawline-regular;
              font-size: 1.5rem;
              display: block;
              margin-bottom: 10px;
            }
            .generic-label {
              @extend .rawline-regular;
              font-size: 1.5rem;
            }
            .ct--select-slider {
              .generic-label {
                margin-bottom: 25px;
                display: block;
              }
            }
            &.line-calendar {
              .flatpickr-calendar {
                margin: 0 auto;
              }
              .multi-day {
                display: block;
                margin-top: 20px;
                @extend .rawline-regular;
                font-size: 1.3rem;
              }
            }
            &.hour-line {
              position: relative;
              margin-top: 40px;
              padding: 0 20px;
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: $dark-beige;
                left: 0;
                top: -15px;
              }
            }
            .line-from-to {
              position: relative;
              display: flex;
              margin-top: 30px;
              padding: 0 20px;
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: $dark-beige;
                left: 0;
                top: -15px;
              }
              .col {
                width: calc(50% - 5px);
                &:nth-child(1) {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .basket {
          height: 100%;
          background: #fff8f0;
          position: relative;
          .is-empty {
            .empty-container {
              display: block;
            }
            .basket-container {
              display: none;
            }
          }
          .empty-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            display: none;
            text-align: center;
            .empty-title {
              font-family: $montserrat;
              font-size: 2rem;
              line-height: 2.4rem;
              font-weight: 300;
              font-style: normal;
              color: $black;
              display: block;
              padding-bottom: 20px;
            }
            .empty-description {
              font-family: $rawline;
              font-size: 1.3rem;
              line-height: 1.7rem;
              color: $basket-li-accessible;
            }
          }
          .basket-container {
            display: block;
          }
        }
        .basket-items {
          // display: none;
          padding: 15px;
          overflow-y: auto;
          background: #fff8f0;
          .basket-item {
            background: $white;
            padding: 25px;
            margin-bottom: 10px;
            &.place {
              .details {
                ul {
                  li {
                    font-size: 1.1rem;
                    padding: 5px 0;
                  }
                }
              }
            }
            .headline {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              &:hover {
                .remove-place {
                  display: flex;
                }
              }
              .edit-event {
                height: 14px;
                width: 14px;
                padding: 0;
                border: 0;
                background-color: transparent;
                align-items: center;
                justify-content: center;

                .icon {
                  color: $basket-li;
                  height: 12px;
                  width: 12px;
                }
              }
              .col {
                .type {
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  .icon {
                    width: 11px;
                    height: 17px;
                    margin-right: 10px;
                  }
                }
                .name {
                  display: block;
                  font-size: 1rem;
                }
                .price {
                  display: block;
                }
                &.left {
                  width: 80px;
                }
                &.right {
                  text-align: right;
                }
              }
              .remove-place {
                position: relative;
                height: 14px;
                width: 14px;
                padding: 0;
                border: 0;

                display: none;
                // display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                .icon {
                  width: 10px;
                  height: 10px;
                  fill: $basket-li;
                }
              }
            }
            .details {
              ul {
                li {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  color: $basket-li;
                  width: 100%;
                  padding: 10px 0;
                  border-top: 1px dotted $basket-li;
                  font-size: 1.3rem;
                  line-height: 1.4rem;
                  &:hover {
                    .right .quantity-item .controllers {
                      display: block;
                    }
                    .remove-item {
                      display: flex;
                    }
                  }
                  span {
                    &.item-name {
                      // width: 125px;
                      padding-right: 15px;
                    }
                    strong {
                      display: block;
                      font-size: 1.1rem;
                      margin-top: 3px;
                    }
                    .icon {
                      width: 9px;
                      height: 12px;
                      fill: $basket-li;
                      margin-left: 3px;
                      &.svg-panier_picto_bouteille {
                        width: 13px;
                        height: 23px;
                      }
                      &.svg-panier_picto_clock {
                        width: 18px;
                        height: 18px;
                      }
                    }
                    &.quantity-col {
                      display: flex;
                      align-items: center;
                      font-size: 1.5rem;
                    }
                  }
                  .right {
                    display: flex;
                    align-items: center;
                    .quantity-item {
                      display: flex;
                      align-items: center;
                      &:nth-of-type(2) {
                        margin-left: 10px;
                      }
                      .controllers {
                        width: 28px;
                        height: 50px;
                        background: $white;
                        border: 1px solid $basket-li;
                        margin: 0 7px 0 5px;
                        button {
                          position: relative;
                          height: 24px;
                          width: 100%;
                          padding: 0;
                          border: 0;

                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background: $white;
                          span {
                            position: absolute;
                            width: 7px;
                            height: 1px;
                            left: 50%;
                            top: 50%;
                            margin-left: -3.5px;
                            margin-top: -0.5px;
                            background: $basket-li;
                          }
                          &.more-quantity {
                            border-bottom: 1px solid $basket-li;
                            span {
                              &:nth-child(1) {
                                transform: rotate(90deg);
                                transform-origin: center center;
                              }
                            }
                          }
                        }
                      }
                    }
                    .duration-time {
                      display: flex;
                      align-items: center;
                      .icon {
                        margin-top: 3px;
                      }
                    }
                  }
                  .remove-item {
                    position: relative;
                    height: 14px;
                    width: 14px;
                    padding: 0;
                    border: 0;

                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                    background: $white;
                    .icon {
                      width: 12px;
                      height: 12px;
                      fill: $basket-li;
                    }
                  }
                }
              }
            }
            .additional-infos {
              color: #83807d;
              font-family: $rawline;
              font-size: 1.2rem;
              margin-top: 4px;
            }
            .services {
              margin-top: 20px;
              .title-services {
                display: block;
                margin-bottom: 15px;
              }
              ul {
                li {
                  display: flex;
                  justify-content: space-between;
                  color: $black;
                  width: 100%;
                  padding: 2px 0;
                  font-size: 1.1rem;
                }
              }
            }
          }
          .vouncher-item {
            display: flex;
            background: $white;
            padding: 25px;
            margin-bottom: 10px;
            align-items: center;
            .icon {
              width: 29px;
              height: 18px;
              margin-right: 15px;
            }
          }
          .delivery-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 25px 0;
            padding: 0 10px;
            .col {
              display: flex;
              align-items: center;
              .icon {
                width: 37px;
                height: 18px;
                margin-right: 15px;
              }
            }
          }
          .clear-selection {
            text-align: center;
            margin: 20px auto;
            .clear-selection-button {
              @extend .text-1;
              background-color: transparent;
              border-color: transparent;
              color: #444;
              cursor: pointer;
              font-size: 1.25rem;
              .icon {
                position: relative;
                top: 1px;
                margin-right: 3px;
              }
              &:hover {
                color: #000;
              }
            }
          }
        }
        .basket-result {
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          position: absolute;
          top: 0;
          bottom: 95px;
          width: 100%;
          left: 0;
          right: 0;
        }
        .total-basket {
          padding: 20px 25px;
          border-top: 1px solid $breadcrumb;
          height: 95px;
          max-height: 95px;
          background: #fff8f0;
          position: fixed;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          .about {
            display: flex;
            justify-content: space-between;
            .col {
              display: flex;
              flex-direction: column;
              text-transform: uppercase;
              &:nth-last-child(1) {
                text-align: right;
              }
              span {
                margin-top: 5px;
                color: $basket-li;
              }
            }
          }
          .btn-submit-basket {
            width: 100%;
            justify-content: center;
            background: $yellow;
            line-height: 1.25;
            padding: 10px;
            height: 45px;
            max-height: 45px;
            .label-btn {
              font-size: 1.3rem;
              text-transform: uppercase;
              font-weight: 400;
            }
          }
          .btn-submit-basket-new {
            height: auto;
            line-height: 1.5;
            padding: 10px 18px;
            width: 100%;
          }
        }
      }
    }
  }
  .js-mobile-filters {
    position: fixed;
    bottom: 40px;
    left: calc(50% - 80px);
    width: 160px;
    z-index: 10;
    text-align: center;
    display: none;
    background: $yellow;
    color: $black;
    font-size: 15px;
    border-radius: 16px;
    line-height: 32px;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 600ms ease-in-out, bottom 600ms ease-in-out;
    @extend .rawline-regular;
    &.show {
      opacity: 1;
      bottom: 86px;
      display: block;
    }
  }
}

.js-popin-filter.show {
  top: 50px !important;
  &:before {
    content: ' ';
    right: 0;
    top: 0;
    position: absolute;
    z-index: 10;
    pointer-events: none;
  }
  h4,
  .js-popin-filter-title {
    position: absolute;
    top: 17px;
    left: 30px;
    font-family: $rawline;
    font-size: 16px;
  }
}

.menu-show {
  .surplus-management-row {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    .surplus-management-icon {
      svg {
        background-color: #3f6a8f;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        fill: white;
      }
    }

    .surplus-management-description {
      padding-left: 5px;
      p {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0;
        line-height: 17px;
      }
    }
  }
}
