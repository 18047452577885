@import 'faq';

.landing-section-faq {
  padding: 0 25px 50px;
  .landing-section-faq-content {
    .landing-section-faq-question {
      display: block;
      margin-bottom: 20px;
      margin-right: 0;
      width: auto;
    }
  }
}
