@import 'brief';

.page.brief {
  padding-top: 50px;

  .ct--breadcrumb {
    margin-bottom: 40px;
    .inner {
      padding: 10px 0 20px 6%;
    }
  }
  .big-title-contact {
    margin-bottom: 45px;
    color: $yellow;
    text-align: center;
  }

  .sizing-1 {
    text-align: center;
    .first-row-contact {
      width: 100%;
      text-align: center;
      .text-content-first-row {
        display: inline-block;
        margin-bottom: 50px;
      }
    }

    .second-row-contact {
      display: inline-block;
      margin: 0 auto -3px;
      width: 70%;
      background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
      height: 37px;
    }
  }

  .sizing-2 {
    width: 100%;
  }
}
