@import 'static-place';

.page.static-place {
  > .ct--header {
    .header {
      padding-top: 0;
    }
  }

  .static-place-alert {
    padding: 15px 20px;
  }

  .ct--breadcrumb {
    margin: 0;
    padding: 10px 20px;
  }

  .static-place-content {
    h2 {
      @extend .title-1;
      margin-top: 10px;
      margin-bottom: 15px;
      line-height: 1.25;
    }
  }

  .static-place-description,
  .static-place-location,
  .static-place-faq {
    padding: 0 20px;
  }

  .static-place-capacity {
    .table-wrapper {
      overflow-x: auto;
    }
  }

  .static-place-location {
    > .cols {
      display: block;
    }
  }

  .static-place-faq {
    .static-place-faq-content {
      display: block;
    }
  }

  .static-place-similar {
    padding: 10px 15px;
    margin: 0 -20px 20px;

    .section-header {
      margin-bottom: 20px;
    }

    .static-place-similar-content {
      display: block;

      .ct--item-result {
        display: block;

        &:last-child:after {
          content: none;
        }
      }
    }
  }
}
