@import 'control-quantity';

.ct.ct--control-quantity {
  width: 45px;
  height: 30px;
  .value {
    width: 40px;
    font-size: 1.1rem;
    .response-value {
      width: 24px;
      margin-top: 0;
    }
  }
  .controllers {
    width: 15px;
    button {
      height: 14px;
      background: $white;
    }
  }
}
