@import 'dashboard';

.page.dashboard {
  padding-top: 95px;
  .ct--button {
    width: 100%;
  }

  .dash-nav {
    height: 45px;
    width: 100%;
    border-bottom: 1px solid $breadcrumb;
    margin-bottom: 15px;
    overflow-x: scroll;
    z-index: 60;
    position: fixed;
    top: 50px;
    background: #fff;

    .list-nav {
      display: flex;
      padding-left: 15px;
      align-items: center;
      height: 100%;
      //white-space: nowrap;
      .list-nav-element {
        position: relative;
        margin-right: 15px;
        height: 25px;
        display: inline-block;
        margin-left: 20px;
        line-height: 25px;
        white-space: nowrap;
        &:last-child {
          padding-right: 20px;
        }

        a {
          display: block;
          height: 100%;
          color: $black;
          text-decoration: none;
        }

        &.select-nav {
          font-weight: 900;
        }
      }
    }
  }

  .dash-content {
    padding: 0 6%;

    .home-dash {
      .ct--button {
        background-color: $light-beige;
        margin-top: 20px;
        .label {
          color: $medium-grey;
          text-transform: none;
        }
      }
    }

    .dash-contact {
      margin-top: 13px;
      padding: 20px 20px 30px;
      text-align: center;

      .title-contact {
        margin-bottom: 10px;
      }

      .text-contact {
        margin-bottom: 10px;
      }

      .phone-number {
        .icon {
          display: inline-block;
          margin-right: 7px;
          width: 15px;
          height: 15px;
          vertical-align: middle;
          position: relative;
          top: -2px;
        }
      }

      .furnisher-contact {
        margin-top: 30px;
        .title-contact {
          margin-bottom: 15px;
        }
      }
    }

    .dash-logout {
      p {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin: 5px 0 35px;
        text-align: center;

        a {
          color: #750009;
        }

        svg {
          color: #750009;
          fill: #750009;
          margin-left: 4px;
          position: relative;
          top: 2px;
        }
      }
    }

    .full-block {
      padding: 24px 21.5px;
      border: 1px solid $dark-beige;
      margin-top: 16px;

      .title-full-block {
        margin-bottom: 23px;
        vertical-align: middle;
        display: inline-block;
        color: $medium-grey;
      }

      .modify {
        float: right;
        vertical-align: middle;
        margin-top: -1px;
        .icon {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 3px;
          margin-bottom: 2px;
          vertical-align: middle;
        }

        a {
          color: $black;
          text-decoration: none;
        }
      }

      .under-full-block {
        border-top: 1px solid $dark-beige;
        border-bottom: 1px solid $dark-beige;
        padding: 19px 7px;
        border-top-style: dotted;
        border-bottom-style: dotted;

        .icon-place {
          vertical-align: middle;
          display: inline-block;
          margin-right: 16px;
          .icon {
            width: 17px;
            height: 17px;
          }
        }

        .under-full-content-text {
          display: inline-block;
          vertical-align: middle;
          span {
            font-weight: 900;
            line-height: 1rem;
          }
        }

        .text-under-block {
          margin-bottom: 12px;
          line-height: 1.15rem;
        }
      }

      .full-block-list {
        margin-top: 25px;

        .element-full-block-list {
          &:nth-child(2n) {
            margin-bottom: 10px;
          }

          &:nth-child(2n + 1) {
            font-weight: 900;
            margin-bottom: 3px;
          }
        }
      }

      .input-box {
        background-color: $light-beige;
        color: $bobby-brown;
        padding: 12px 17px;
        display: block;
        border: none;
        margin-bottom: 10px;
        max-height: 35px;
        width: 100%;
        text-decoration: none;
      }
    }

    .newsletter {
      .ct--button {
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .under-full-block {
        margin-bottom: 0;

        .news-icon {
          vertical-align: top;
          margin-right: 13px;
          display: inline-block;

          .icon {
            width: 18px;
            height: 13px;
          }
        }

        .text-under-block {
          vertical-align: middle;
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }

    .password {
      .ct--button {
        margin-top: 8px;
        border: none;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
        }
      }

      .under-input {
        display: block;
        margin-top: -5px;
        margin-bottom: 5px;
      }

      .under-full-block {
        border-bottom: none;
        padding-bottom: 10px;
      }
    }

    .account-type {
      .ct--button {
        margin-top: 13px;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .under-full-block {
        border-bottom: none;

        .button-radio {
          margin-bottom: 3px;
        }
      }
    }

    .personnal {
      margin-bottom: 25px;

      .ct--button {
        border: none;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
        }
      }
      .title-personnal {
        vertical-align: middle;
        display: inline-block;
      }

      .modify-link {
        float: right;
        vertical-align: middle;

        .icon {
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 12px;
        }

        a {
          text-decoration: none;
          color: $black;
        }
      }

      .personnal-content {
        border-top: 1px solid $dark-beige;
        margin-top: 27px;
        margin-bottom: 23px;
        padding-top: 18px;

        .left-col {
          width: 50%;
          display: inline-block;

          .icon {
            width: 35px;
            height: 35px;
            vertical-align: middle;
            margin-right: 12px;
          }

          .list-personnal {
            vertical-align: middle;
            display: inline-block;

            .element-list-personnal {
              &:first-child {
                font-weight: 900;
              }
            }
          }
        }

        .right-col {
          padding-left: 40px;
          display: inline-block;
          vertical-align: top;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: $dark-beige;
            opacity: 0.8;
          }

          .list-personnal {
            vertical-align: top;
            display: block;
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }

            .element-list-personnal {
              &:first-child {
                font-weight: 900;
              }
            }
          }

          .form-input {
            display: block;
            margin-top: 10px;
            padding: 15px 20px;
            min-width: 340px;
            background-color: $light-beige;
            border: none;
          }
        }
      }
    }

    .empty {
      min-height: 134px;
    }

    .table-box {
      h2 {
        font-size: 18px;
      }
      .table-block {
        a {
          color: $black;
        }
        border-bottom: solid 1px $dark-beige;
        padding-bottom: 16px;
        padding-top: 16px;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
          border-bottom: none;
        }

        .left-col {
          position: relative;
          width: 100%;
          height: 100%;

          span {
            font-weight: 900;
          }

          .right-col {
            position: absolute;
            width: 50%;
            right: 0;
            top: 0;
            height: 100%;

            .icon {
              position: absolute;
              width: 20px;
              height: 10px;
              top: 50%;
              left: 50%;
              transform: translate(-50% -50%);
            }
          }
        }
      }
    }
  }
}
