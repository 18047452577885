@import 'seminaire-landing';

.page.seminaire-landing {
  margin-bottom: 50px;

  .row-form {
    margin: 0;
    padding: 0 6%;

    #formulaire {
      top: -50px;
    }

    .row-form-content {
      p {
        margin-bottom: 20px;
      }
    }

    .row-form-form {
      margin-top: 30px;

      .hs-form {
        box-shadow: none;
        padding: 0;
      }
    }
  }

  .linked-pages-block {
    margin: 20px 0 40px;

    .block-content {
      .block-content-content {
        padding: 0;

        .splide {
          .splide__slide {
            > a {
              height: 300px;

              .splide__caption {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
