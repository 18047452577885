@import 'double-col-slider';

.ct.ct--double-col-slider {
  .container {
    padding: 0 6%;
    .cols {
      display: flex;
      flex-direction: column-reverse;
      .col-content {
        margin-top: 20px;
      }
      .col {
        &.col-picture {
          .slider {
            margin-top: 50px;
            &:after {
              display: none;
            }
            .bullets {
              li {
                width: 6px;
                height: 6px;
              }
            }
          }
          .headline {
            position: relative;
            left: auto;
            margin-bottom: 40px;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
}
