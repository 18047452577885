@import 'team';

.page.team {
  padding-top: 90px;
  padding-bottom: 20px;
  .content-cgu {
    h1 {
      font-size: 3rem;
    }
  }
}
