@import 'index';

.page.animation-index,
.page.menu-index {
  margin-top: 0 !important;

  .intro-page-result {
    padding: 15px 25px;
    margin-bottom: 0;
  }

  .animations-grid {
    padding: 15px 25px;

    .animations-grid-row {
      display: block;
      margin: 0;

      .animations-grid-item {
        margin-bottom: 15px;
        padding: 45px 10px;
        width: 100%;
      }
    }
  }
}
