@import '../../variables/colors';

.menuburger-header {
  background: $medium-grey;
  color: $light-beige;
  .close-menuburger {
    position: absolute;
    top: 16px;
    right: 28px;
    .icon {
      height: 18px;
      width: 18px;
      fill: $yellow;
      color: $yellow;
    }
  }
  .menuburger-title {
    display: block;
    line-height: 50px;
    text-transform: uppercase;
  }
}
.menuburger-content {
  // main list
  .menuburger-list {
    padding: 15px;
    border-bottom: 1px solid $dark-beige;
    .menuburger-list-content {
      margin: 5px 0;
      &:first-child {
        margin: 0;
      }
      a {
        display: block;
        padding: 10px 0;
        color: $black;
        font-size: 1.4rem;
        &.blue-link {
          color: $light-duck-accessible;
        }
      }
      &.separator {
        margin: 15px 0;
        padding: 0;
        background-color: $dark-beige;
      }
      &.menuburger-phone {
        svg {
          position: relative;
          top: 3px;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          stroke: $black;
        }
      }
    }
  }
  // sitemap
  .menuburger-sitemap {
    overflow: hidden;
    padding: 15px;
    border-bottom: 1px solid $dark-beige;
    .list-sitemap {
      display: flex;
      width: 100%;
      .menuburger-list-sitemap {
        flex: 1;
        vertical-align: middle;
        margin: 5px 0;
        a {
          color: $black;
          font-size: 1.25rem;
          line-height: 1.5;
          display: block;
          padding: 5px;
        }
      }
    }
  }
  // rs
  .menuburger-rs {
    padding: 20px;
    .facebook,
    .twitter,
    .instagram {
      display: inline-block;
      margin: 0 5px;
      padding: 10px;
      .icon {
        color: $black;
        fill: $black;
        width: 18px;
        height: 18px;
      }
    }
    .snapmag {
      display: block;
      color: $black;
      padding: 10px;
      font-size: 1.3rem;
    }
  }
  // utils
  .menuburger-utils {
    padding: 20px;
    border-top: 1px solid $dark-beige;
    a {
      display: inline-block;
      padding: 10px;
      font-size: 1.2rem;
      color: $black;
    }
  }
}
