@import 'instagram-net';

.ct--instagram-net {
  text-align: center;
  padding: 35px 6% 50px;
  background-color: $light-beige;

  .instagram-title {
    color: $yellow;
    margin-bottom: 33px;
    font-family: 'Vanitas';
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    font-size: 3.5rem;
  }

  .instagram-image {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    display: block;
    display: flex;
    flex-wrap: wrap;

    .image-element {
      position: relative;
      width: calc(100% / 3);
      display: block;

      a {
        font-size: 0;
        display: block;
        img {
          width: 100%;
        }
      }
    }

    &::after {
      position: absolute;
      content: '';
      width: 70%;
      height: 28px;
      bottom: -26px;
      background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .subtitle-instagram {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .sub-text-instagram {
    margin-bottom: 29px;
  }

  .list-instagram {
    display: inline-block;
    margin-top: 20px;

    .element-list-instagram {
      display: inline-block;
      margin-right: 28px;

      &:last-child {
        margin-right: 0;
      }

      .icon {
        width: 15px;
        height: 15px;
        color: $yellow;
        fill: $yellow;
      }
    }
  }
}
