@import 'search-enhanced';

.search-main {
  //z-index: 100; // todo : add a class when popup is open => would fix z-index, scroll
  .search-wrapper {
    .search-result {
      .search-list-enhanced {
        .filters-bar {
          .filters {
            .nav-3 {
              text-transform: none;
            }

            .control-label {
              color: $black;
              font-size: 13px;
              font-weight: 300;
              text-transform: none;
            }

            .popin-filter-search,
            .popin-all-filters {
              .filter-actions {
                .clear-filters,
                .apply-filters {
                  display: block;
                  margin: 20px 0;
                  width: 100%;
                }
              }
            }

            .popin-all-filters {
              border: none;
              height: calc(100vh - 108px);
              padding: 50px 20px 15px !important;

              .filter-section-title {
                font-size: 16px;
                margin: 25px 0;

                .icon {
                  font-size: 20px;
                }
              }

              .filter {
                .ct--filter {
                  height: 40px !important;
                  // exceptions
                  &.ct--enhanced-range {
                    height: unset !important;
                  }
                }
              }

              // exceptions
              .enhanced-range-filter {
                margin-bottom: 45px;
              }

              .flatpickr-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

body.modal-open {
  .search-main {
    z-index: 999 !important;
    .popin-all-filters {
      height: 100% !important;
      top: 0 !important;
    }
  }
}
