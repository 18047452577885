@import 'button';

.ct.ct--button {
  height: 45px;
  &.arrow {
    width: 80%;
    justify-content: space-between;
    padding: 0 20px;
  }
  .label-btn {
    font-size: 1.2rem;
  }
}

.cta-spacer {
  text-align: center;
}
