.ct.ct--submenu-search {
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: max-height 0.5s $ease-out-cubic;
  overflow: hidden;
  text-align: center;
  z-index: 50;
  &.is-open {
    .submenu-dropdown-head {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  .submenu-list li {
    background-color: rgba(67, 67, 67, 0.9);
    height: 58px;
    width: calc(100% / 6);
    float: left;
    .submenu-link {
      display: block;
      text-align: center;
      color: $white;
      font-size: 1rem;
      font-family: $rawline;
      .submenu-icon {
        display: block;
        padding: 11px 0 5px;
        text-align: center;
        svg {
          display: inline-block;
          height: 20px;
          width: 20px;
          fill: $white;
        }
      }
    }
    &.active {
      background-color: $black;
      .submenu-link {
        color: $yellow;
        .submenu-icon svg {
          fill: $yellow;
        }
      }
    }
  }
}
