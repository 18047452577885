@import 'contact';

.page.contact {
  %pattern {
    background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
      center center repeat;
    content: '';
    height: 66%;
    position: absolute;
    width: 66%;
    z-index: 1;
  }

  padding-top: 50px;
  background-image: url('../../../../../../web/medias/img/mobile/pages/about-us/about-bg.png');
  background-position: 200px -70px;
  background-size: 340px;
  background-repeat: no-repeat;

  .ct--breadcrumb {
    margin-bottom: 40px;

    .inner {
      padding: 10px 0 20px 6%;
    }
  }

  .big-title-contact {
    @extend .keyword;
    margin-bottom: 45px;
    color: $yellow;
    text-align: center;
    font-size: 3rem;
  }

  .sizing-1 {
    .first-row-contact {
      padding: 0 20px;
      text-align: left;

      .text-content-first-row {
        margin-bottom: 50px;
        line-height: 1.75;

        &.text-legal {
          margin-top: 80px;
        }

        a {
          color: $yellow;
          font-weight: bold;

          &:hover {
            color: darken($yellow, 10%);
          }
        }
      }
    }
  }

  .sizing-2 {
    position: relative;
    width: 100%;

    &:before {
      @extend %pattern;
      height: 100%;
      width: 80%;
      right: 10%;
      top: -25px;
      left: 10%;
    }

    .contact-form {
      background-color: #fff8f0;
      padding: 45px 37px;
      width: 100%;
      z-index: 2;
      position: relative;

      .form-contact-place {
        width: 100%;
        text-align: left;
        min-height: 300px;
        background: none;
        max-width: 300px;
        margin: 0 auto;

        label,
        .form-control {
          font-size: 1.2rem;
        }
      }
    }
  }
}
