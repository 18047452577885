@import 'newsletter';

.ct.ct--newsletter {
  .bg {
    .icon {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }
  .inner {
    padding: 100px 6%;
  }
  .input-newsletter {
    input {
      height: 50px;
      line-height: 50px;
    }
  }
}
