@import 'news-item';

.ct--news-item {
  margin-bottom: 20px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  .about {
    padding: 15px;
    text-align: left;
    padding-bottom: 40px;

    .title-news {
      margin: 11px 0 9px;
    }
  }
}
