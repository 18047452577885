@import './404';

body {
  main {
    .page.error._404,
    .page.error.autoconnect {
      padding-top: 50px;
      background-size: 130%;

      > .content {
        padding: 50px 20px;

        .container-big-number {
          height: 70px;
          margin-bottom: 20px;

          .big-number {
            font-size: 11.2rem;
          }
        }

        .title-error {
          font-size: 1.2rem;

          .break {
            display: block;
          }
        }

        .text-error {
          font-size: 1.1rem;
          margin: 22px 0;
        }

        .ct--button {
          width: auto;
          height: 35px;
        }

        .links-error {
          margin: 30px -20px 20px;

          .links-error-row {
            > .ct {
              &:after {
                display: none;
              }

              .about,
              .content {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
