@import 'seminar';

.page.seminar {
  .row-introduction {
    padding: 20px 6% 60px;

    .row-introduction-content {
      margin: 20px 0;
    }

    .row-introduction-icons {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 40px;

      li {
        margin-bottom: 20px;
      }
    }
  }

  .logos-slider {
    margin: -30px 0 35px;
  }

  .row-form {
    margin: -40px 0 0;
    padding: 0 6% 100px;
    text-align: center;

    #formulaire {
      top: -110px;
    }

    .row-form-form {
      margin-top: 30px;

      .hs-form {
        box-shadow: none;
        padding: 0;
      }
    }
  }
}
