@import './center.scss';

.notification-center {
  left: 10px;
  right: 10px;
  top: 100px;
  width: auto;

  .notification {
    padding: 30px 40px 30px 20px;
  }
}
