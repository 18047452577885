@import 'register';

.page.user.register {
  &.events {
    .title-2-register {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    a {
      font-size: 10px;
    }
    .register-account {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  padding-top: 50px;
  background: none;

  .title-2-register {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .big-title-register {
    font-size: 1.7rem;
  }
  .container {
    padding: 10px 7%;
    .text-content {
      text-align: left;
      .headline {
        font-size: 1.1rem;
        br {
          display: none;
        }
      }
    }
    .register-account {
      form {
        .section-additional-info {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        flex-wrap: nowrap;
        flex-direction: column;

        .container-btn-radio {
          width: 100%;

          .button-radio {
            .label {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
