@import 'brief-modal';

.selection-modal.edit-event-modal {
  margin: 0;
  max-width: none;
  min-height: 100vh;
  width: 100%;

  .selection-modal-content {
    display: block;
    height: auto;
    min-height: 100vh;
    padding: 30px 20px 20px;

    form {
      .form-header {
        margin-top: 5px;
        .title {
          font-size: 2rem;
        }
        .subtitle {
          font-size: 1.25rem;
          line-height: 1.5;
        }
        .title,
        .subtitle {
          span {
            display: block;
          }
        }
      }

      h3 {
        font-size: 18px;
        text-align: center;
      }

      label {
        color: #272727;
      }

      .form-body {
        min-height: 0;
        padding-right: 0;

        .form-row {
          display: block;
          margin-bottom: 20px;
          width: 100%;

          .form-col {
            display: block;
            margin-right: 0 !important;
            width: auto;
          }
        }

        .form-title input {
          max-width: none;
        }

        select,
        .multiselect {
          max-width: none;
        }

        .form-date,
        .form-budget {
          .icon {
            display: none;
          }
        }

        .form-row-date {
          .fields {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 0;

            .form-date {
              grid-area: 1 / 1 / 2 / 3;
              margin-bottom: 6px;
              margin-right: 0;
              width: 100%;
            }

            .form-time {
              margin-right: 0;
              min-width: auto;
              position: initial;
              top: 0;
              width: 100%;

              &.form-time-start {
                grid-area: 2 / 1 / 3 / 2;
              }

              &.form-time-end {
                grid-area: 2 / 2 / 3 / 3;
              }
            }
          }

          .flatpickr-wrapper {
            display: block;
          }

          .more {
            margin-left: 0;
          }
        }

        .form-guests {
          font-size: 12px;
        }
      }

      .form-control {
        font-size: 14px;
      }

      .multiselect {
        * {
          font-size: 14px;
        }

        &.full-mobile {
          .multiselect__content-wrapper {
            * {
              font-size: 15px;
            }

            background: #fff;
            bottom: 0;
            left: 0;
            max-height: none !important;
            position: fixed;
            right: 0;
            top: 0;

            .multiselect__content {
              .list-title {
                display: block;
                font-weight: bold;
                padding: 20px 10px 15px;
                text-align: center;
                text-transform: uppercase;

                .close-button {
                  top: 20px;
                }
              }

              .multiselect__element {
                .multiselect__option {
                  padding: 12px 20px;

                  &.multiselect__option--highlight {
                    background: transparent;
                    color: inherit;
                  }

                  .icon {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .form-actions {
        button {
          display: block;
          font-size: 14px;
          float: none;
          height: 45px;
          line-height: initial;
          margin: 10px 0;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
