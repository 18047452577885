@import 'login';

.page.user.login {
  padding: 50px 0 20px;
  button.connexion {
    font-family: 'Montserrat';
    font-weight: 300;
    span {
      font-size: 10px !important;
    }
  }

  .title-2-login {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  .link-login {
    font-size: 0.7rem;
  }

  .big-title-login {
    font-size: 1.7rem;
  }

  .container-col {
    padding: 0 7%;
    flex-direction: column;
    .new-account {
      width: 100%;
      padding-right: 0;
      padding: 27px 0;

      .headline {
        font-size: 1.1rem;
      }
      .advantages-account {
        font-size: 0.9rem;
      }
    }
    .connexion-account {
      width: 100%;
      padding-left: 0;
      border-left: none;
      border-top: 1px solid $breadcrumb;
      form {
        .connexion {
          font-size: 1rem;
        }
        &.generic-form .form-control {
          display: block;
          width: 100%;
          font-size: 1.5rem;
          height: 48px;
          padding: 0 20px;
          color: #272727;
          background-color: #fdf4e9;
          border: 1px solid #fdf4e9;
        }
      }
    }
  }
}
