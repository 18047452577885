@import 'navbar';

.ct.ct--navbar {
  position: fixed;
  width: 100%;
  z-index: 60;

  // Navbar

  .first-row-navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    transform-origin: 100% 0;
    transition: margin-top 250ms ease-in-out;
    margin-top: 0;
    height: 50px;
    padding-left: 6%;
    padding-right: 6%;
    z-index: 2;
    position: relative;
    background: $medium-grey;

    .backdrop {
      position: absolute;
      height: 100vh;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $medium-grey;
      transform: scaleX(1);
      transition: opacity 400ms ease-in-out;
      transform-origin: 0 100%;
      opacity: 0;
      pointer-events: none;
      z-index: 10;
    }

    &.show {
      .backdrop {
        pointer-events: auto;
        opacity: 0.5;
      }
    }

    .first-list-navbar {
      float: right;

      .first-list-nav {
        display: block;
        position: relative;
        z-index: 9;
        margin-right: -5px;

        .elements-first-list-nav {
          display: inline-block;
          margin-right: 30px;
          width: 100px;
          color: $yellow;
        }

        li {
          background-color: transparent;
          display: inline-block;
          cursor: pointer;
          position: relative;
          margin-bottom: 0;
          z-index: 10;
          box-sizing: border-box;
          padding: 5px 8px;
          margin: 0 2px;

          .icon {
            display: inline-block;
            color: $yellow;
            fill: $yellow;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          &.mycart,
          &.myaccount,
          &.menuburger-icon {
            display: inline-block;
          }

          &.mycart {
            .quantity {
              @extend .rawline-bold;
              align-items: center;
              background: $light-duck;
              border-radius: 50%;
              color: $white;
              display: flex;
              font-size: 0.9rem;
              height: 15px;
              justify-content: center;
              pointer-events: none;
              position: absolute;
              right: -1px;
              top: -2px;
              width: 15px;
            }
          }
        }
      }
    }

    .logonavbar {
      position: relative;
      z-index: 9;
      max-width: 140px;
      margin-top: -4px;
      float: left;

      .icon {
        width: 140px;
        height: 36px;
        color: $white;
        fill: $white;
      }
    }
  }

  //Menu Burger

  .menuburger {
    padding: 0;
    width: calc(100% - 65px);
    max-width: 480px;
    height: 100vh;
    position: absolute;
    top: 0;
    right: -480px;
    background-color: $light-beige;
    text-align: center;
    color: $medium-grey;
    transition: right 250ms ease-in-out;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    &.show {
      right: 0;
    }
    @import './navbar.burger.mobile';
  }

  &.scroll {
    .first-row-navbar {
      margin-top: -50px;
    }
  }

  &.opaque {
    .first-row-navbar {
      &::before {
        background-color: $medium-grey;
      }
    }
  }
}

.sgp-body {
  .ct.ct--navbar .first-row-navbar .logonavbar .icon {
    aspect-ratio: 112/63;
    width: auto;
    height: 36px;
  }
}
