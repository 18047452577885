@import 'faq';

.page.faq {
  padding: 40px 6% 0;
  background-image: url('../../../../../../web/medias/img/mobile/pages/about-us/about-bg.png');
  background-position: 200px -70px;
  background-size: 340px;
  background-repeat: no-repeat;

  .ct--breadcrumb {
    margin-bottom: 30px;
    .inner {
      padding: 20px 0;
    }
  }
  .big-title-faq {
    text-align: center;
    color: $yellow;
    margin-bottom: 50px;
  }

  .sub-big-title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 23px;

    a {
      color: $black;
      transition: color 600ms ease-in-out;

      &:hover {
        color: $yellow;
      }
    }
  }

  .wrapper-expand {
    margin-bottom: 24px;

    .expand {
      height: 0;
      transition: height 500ms ease-in-out;
      overflow: hidden;

      .inner-expand {
        padding-top: 20px;
        > p,
        > ul li {
          @extend .text-1;
          a {
            color: $yellow;
          }
        }
        > ul li {
          display: block;
          margin-left: span(0.5 of 8);
          position: relative;

          &::before {
            position: absolute;
            content: '';
            left: -8px;
            width: 4px;
            height: 1px;
            background: $yellow;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .topic-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .topic-row,
  .ask-personal-row {
    .wrapper-expand {
      &.open {
        .title-topic {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      .title-topic {
        position: relative;
        padding-right: 35px;
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 1.6rem;
        svg {
          position: absolute;
          right: 0;
          top: 3px;
          width: 10px;
          height: 10px;
          fill: $medium-grey;
          transform-origin: center center;
          transition: transform 0.4s $ease-out-cubic;
        }
      }
    }
  }

  .ask-row {
    .title-ask-row {
      padding: 0 0 20px;
      border-bottom: solid 1px $dark-beige;
      font-size: 1.9rem;
      line-height: 1.9rem;
    }
  }

  .topic-row {
    padding-top: 20px;
    .know-more {
      margin-top: 25px;
    }
  }

  .personal-row {
    .title-personal-row {
      font-size: 1.9rem;
      line-height: 1.9rem;
      padding: 20px 0;
      border-top: solid 1px $dark-beige;
      border-bottom: solid 1px $dark-beige;
    }
  }
}
