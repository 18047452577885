@import '../../variables/colors';
@import '../../variables/eases';
@import './show';

.search-main .show {
  h2.title-2 {
    font-size: 17px;
  }

  .pictures-slider-wrapper {
    &.full {
      &:before {
        padding-bottom: 69%;
      }
    }
  }

  &:before {
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity $ease-in-quad 500ms;
    pointer-events: none;
    z-index: 80;
  }

  &.opacify {
    &:before {
      pointer-events: all;
      opacity: 1;
    }
  }

  .filter-items-popin {
    position: fixed;
    top: 0;
    left: 0;
    // height: calc(100% - 50px);
    height: 100%;
    padding-top: 50px;
    overflow-y: auto;
    background: $white;
    z-index: 100;
    transform: translate3d(-100vw, 0, 0);
    transition: all $ease-in-quad 500ms;
    pointer-events: none;

    .no-parameter {
      line-height: 2em;
    }

    &.open {
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    }

    .all-filters-item {
      padding: 55px 7% 125px;
      width: 100%;
      height: calc(100% - 35px);
      overflow-y: auto;
      position: relative;
      min-width: 85vw;

      .cross-close {
        position: absolute;
        right: 30px;
        top: 20px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        z-index: 1;

        svg {
          width: 16px;
          height: 16px;
          fill: $medium-grey;
          color: $medium-grey;
          cursor: pointer;
        }
      }

      .button-container-submit {
        position: fixed;
        width: 100%;
        background: $light-beige;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 10;
        box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.1);
        .button-container-actions {
          padding: 15px 30px;
          border-top: 1px solid $dark-beige;
        }
        a {
          text-align: center;
          margin: 0;
          width: 100%;
          display: block;
          line-height: 45px;
          span {
            display: inline;
          }
        }
      }

      .btn-valid-filters {
        width: 100%;
        justify-content: center;
      }

      .additional-filters {
        margin-bottom: 25px;
        border-bottom: 1px solid $dark-beige;
        > .duration,
        > .quantity,
        > .options {
          .generic-label {
            font-size: 1rem;
            padding-left: 5px;
            display: inline-block;
            color: $yellow;
            margin-bottom: 10px;
          }
        }
        .options {
          margin-bottom: 20px;
        }
      }
    }
    .bottom-filters {
      width: 100%;
      height: 35px;
      background: $light-beige;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 7%;
      text-transform: uppercase;
    }
    .filter-item {
      margin-bottom: 20px;
      a {
        color: $black;
        &.underline {
          text-decoration: underline;
        }
      }
      &.center {
        text-align: center;
      }
      .title-sidebar-filter {
        @extend .rawline-regular;
        font-size: 1.5rem;
        display: block;
        margin-bottom: 10px;
      }
      &.line-calendar {
        .calendar-filter-date {
          display: block;
          width: 100%;
          height: 35px;
          line-height: 35px;
          @extend .rawline-regular;
          background: $medium-beige !important;
          font-size: 1.3rem;
        }
        .flatpickr-wrapper {
          display: block;
        }
        .multi-day {
          display: block;
          margin-top: 20px;
          @extend .rawline-regular;
          font-size: 1.3rem;
          text-align: center;
        }
      }
      .generic-label {
        font-size: 1rem;
        padding-left: 5px;
        display: inline-block;
        color: $yellow;
        margin-bottom: 10px;
      }
      .line-from-to {
        position: relative;
        display: flex;
        margin-top: 30px;
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background: $dark-beige;
          left: 0;
          top: -15px;
        }
        .col {
          width: calc(50% - 5px);
          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
  > .bottomnav {
    position: relative;
    width: 100%;
    //height: 35px;
    bottom: 0;
    left: 0;
    padding: 0;
    height: auto;
    z-index: 20;
    margin-bottom: -80px;
    &.fixed {
      position: fixed;
      margin-bottom: 0;
    }
    .filter-quantity-topnav {
      background: $medium-beige;
      width: 40%;
      margin-right: 0;
      display: flex;
      justify-content: center;
      .icon {
        width: 12px;
        height: 9px;
      }
    }
    .bottom-price-sheet {
      width: 100%;
      height: 35px;
      background: $light-beige;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 7%;
      text-transform: uppercase;
      box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.1);
    }
    .bottomnav-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 15px 30px;
      background: $light-beige;
      border-top: 1px solid $dark-beige;
      .add-options {
        width: 100%;
        justify-content: center;
      }
    }
    .bottom-disabled-sheet {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: $light-beige;
      text-transform: uppercase;
      border-top: 1px solid $dark-beige;
      text-align: center;
      @extend .rawline-semi-bold;
      font-size: 1.3rem;
    }
  }
  > .topnav {
    height: auto;
    padding: 15px 25px;
    .col {
      &:nth-child(1) {
        width: 80%;
      }
    }
    .price-tooltip {
      display: inline-block;
      margin-left: 10px;
    }
  }
  > .show-wrapper {
    padding: 10px 7% 20px;
    overflow-x: hidden;
    > .headline-before {
      p {
        font-size: 10px;
      }
    }
    > .headline {
      margin-bottom: 20px;
      padding-bottom: 20px;
      .comments {
        font-size: 14px;
      }
    }
    .cols {
      &.cols-double {
        display: block;
        .col {
          width: 100%;
          padding-right: 0;
          &:nth-child(1) {
            padding-right: 0;
            margin-bottom: 30px;
          }
        }
      }
      .list-fiche {
        li {
          width: 100%;
        }
      }
      .ideal {
        li {
          min-width: 30%;
          width: 30%;
        }
      }
      .access {
        li {
          width: 30%;
          min-width: 30%;
        }
      }
      &.public-transports-wrapper {
        .public-transports,
        .points-of-interest {
          margin-bottom: 30px;
          .title-row-fiche {
            font-size: 1.5rem;
          }
        }
      }
      .subway-station-list {
        li {
          .duration {
            font-size: 1.1rem;
          }
        }
      }
    }
    .row-fiche {
      margin-bottom: 30px;
      .title-row-fiche {
        margin-bottom: 25px;
      }
      .table-wrapper {
        padding: 0 1px;
        overflow-y: auto;
      }
      &.row-in-short {
        .list-fiche {
          width: 100%;
        }
        .cols {
          display: block;
          .recommendations {
            display: flex;
            margin-top: 30px;
            line-height: 20px;
          }
        }
      }
      &.row-item-results {
        .item-results {
          display: block;
          .ct.ct--item-result {
            width: 100%;
            &:nth-child(1) {
              margin: 0;
            }
          }
        }
      }
      &.row-menu {
        margin-bottom: 10px;
        .about-menu {
          justify-content: flex-start;
          li {
            width: calc(100% / 3);
            margin-bottom: 30px;
            padding: 0 15px;
            span {
              font-size: 1rem;
              line-height: 1.3rem;
            }
          }
        }
      }

      &.row-equipment {
        > section {
          margin-bottom: 10px;

          h3 {
            border-bottom: none;
            font-size: 1.4rem;
            margin-bottom: 15px;
            padding: 0;
          }

          > section {
            flex-direction: column;

            ul {
              margin-bottom: 20px;
              padding: 0;
            }
          }
        }
      }

      &.row-comments {
        .comment-results {
          .show-more-comments-wrapper {
            text-align: center;
          }
          .result-raw {
            .images {
              > a {
                margin-right: 0;
              }
            }
          }
        }
      }

      &.expandable-description-trigger {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      &.hide-description {
        overflow: hidden;
        transition: height 0.35s ease-out 0s;
        margin-bottom: 10px;

        &.collapsed::before {
          content: '';
          width: 90%;
          height: 60px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff);
          display: block;
          position: absolute;
          margin-top: 245px;
        }
      }
    }
  }

  // tabs
  .nav-tabs {
    display: none;
  }

  .tab-content {
    .tab-pane {
      display: block;
    }
  }
  .warning-container {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  #comments-anchor-js {
    font-size: 14px;
  }
}

.row-place-similar {
  .row-place-similar-content {
    display: block;

    .ct--item-result {
      display: block;

      &:last-child:after {
        content: none;
      }
    }
  }
}
