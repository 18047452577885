@import './input-account';

.row-input-account {
  .input-account {
    height: 40px;
  }
  .message-error {
    font-size: 0.8rem;
  }
}
