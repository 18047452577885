@import 'select-checkbox-multiple';

.ct--select-checkbox-multiple .filter-choice .response-filter {
  font-size: 1.2rem;
}

.ct--select-checkbox-multiple .filter-select-choice .select-choice .label,
.ct--select-checkbox-multiple .filter-select-choice .all-choices .label,
.ct--select-checkbox-multiple .filter-choice .response-filter,
.ct--select-checkbox-multiple .filter-select-choice .head-choice span,
.ct--select-checkbox-multiple
  .filter-select-choice
  .response-filter.from-sidebar
  li
  .label-filter {
  font-family: $rawline;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: none;
}

.ct--select-checkbox-multiple
  .filter-select-choice
  .response-filter.from-sidebar
  li {
  display: inline-block;
  position: relative;
  padding: 0 40px 0 15px;
  .icon {
    position: absolute;
    right: 10px;
    top: 13px;
  }
}

.ct--select-checkbox-multiple
  .filter-select-choice
  .response-filter.from-sidebar
  li
  .label-filter,
.ct--select-checkbox-multiple .filter-choice .response-filter,
.ct--select-checkbox-multiple .filter-select-choice .head-choice span {
  font-weight: bold;
  display: block;
  height: 100%;
  line-height: 35px;
}
