@charset "UTF-8";

/*********************
Vendors
*********************/

@import '~nouislider/distribute/nouislider.css';
@import '~@splidejs/splide/dist/css/splide.min.css';

/*********************
Utils & Variables
*********************/

@import 'utils/accessibility';
@import 'utils/mixins';
@import 'variables/colors';
@import 'variables/eases';
@import 'variables/fonts';
@import 'variables/grid';
@import 'utils/functions';
@import 'utils/normalize';
@import 'utils/reset';
@import 'utils/grid.mobile.scss';

/*********************
Common
*********************/
@import 'common/texts/texts.mobile';
@import 'common/layout/layout.mobile';
@import 'common/search/search.mobile';
@import 'common/search/search-enhanced.mobile';
@import 'common/search/index.mobile';
@import 'common/featured/featured.mobile';
@import 'common/show/show.mobile';
@import 'common/input/input.mobile';
@import 'common/input-account/input-account.mobile';
@import 'common/input-contact/input-contact.mobile';
@import 'common/notification/center.mobile';
@import 'common/notification/toaster.mobile';

/*********************
Components
*********************/

@import 'components/menu/menu.mobile';
@import 'components/key-figures/key-figures.mobile';
@import 'components/double-col/double-col.mobile';
@import 'components/double-col-slider/double-col-slider.mobile';
@import 'components/double-col-slider-companies/double-col-slider-companies.mobile';
@import 'components/newsletter/newsletter.mobile';
@import 'components/footer/footer.mobile';
@import 'components/header/header.mobile';
@import 'components/last-news/last-news.mobile';
@import 'components/navbar/navbar.mobile';
@import 'components/button/button.mobile';
@import 'components/select-checkbox/select-checkbox.mobile';
@import 'components/select-checkbox-multiple/select-checkbox-multiple.mobile';
@import 'components/select-slider/select-slider.mobile';
@import 'components/popins/popins.mobile';
@import 'components/navbar-search/navbar-search.mobile';
@import 'components/navbar-account/navbar-account.mobile';
@import 'components/item-result/item-result.mobile';
@import 'components/calendar/calendar.mobile';
@import 'components/select-range/select-range.mobile';
@import 'components/slider-item/slider-item.mobile';
@import 'components/control-quantity/control-quantity.mobile';
@import 'components/news-item/news-item.mobile';
@import 'components/instagram-net/instagram-net.mobile';
@import 'components/breadcrumb/breadcrumb.mobile';
@import 'components/select-search/select-search.mobile';
@import 'components/submenu-search/submenu-search';
@import 'components/loader/loader';
@import 'components/lazy-load/lazy-load';
@import 'components/admin-panel/admin-panel';
@import 'components/gallery/gallery';
@import 'components/blog/articles.mobile';
@import 'components/selection/selection.mobile';
@import 'components/selection-modal/selection-modal.mobile';
@import 'components/tooltip/tooltip';
@import 'components/alert/alert';
@import 'components/zones/zones.mobile';
@import 'components/faq/faq.mobile';
@import 'components/filters/filters.mobile';
@import 'components/generic-modal/generic-modal.mobile';
@import 'components/category-slider/category-slider.mobile';
@import 'components/trends/trends.mobile';
@import 'components/content-block/content-block.mobile';
@import 'components/logos-slider/logos-slider.mobile';

/*********************
Pages
*********************/

@import 'pages/homepage/homepage.mobile';
@import 'pages/contact/contact.mobile';
@import 'pages/about-us/about-us.mobile';
@import 'pages/faq/faq.mobile';
@import 'pages/confidentiality/confidentiality.mobile';
@import 'pages/rent/rent.mobile';
@import 'pages/zones/zones.mobile';
@import 'pages/event/event.mobile';
@import 'pages/dashboard/dashboard.mobile';
@import 'pages/error/404/404.mobile';
@import 'pages/hellosign/hellosign-confirmation.mobile';
@import 'pages/team/team.mobile';
@import 'pages/tos/tos.mobile';
@import 'pages/events/events.mobile';
@import 'pages/charte-qualite/charte-qualite.mobile';
@import 'pages/brief/brief.mobile';
@import 'pages/seminar/seminar.mobile';
@import 'pages/seminar/seminaire-landing.mobile';
@import 'pages/static-place/static-place.mobile';

/*********************
User
*********************/

@import 'pages/user/login/login.mobile';
@import 'pages/user/register/register.mobile';
@import 'pages/user/guest/guest.mobile';
@import 'pages/user/transmission-request/transmission-request.mobile';
@import 'pages/user/validation-request/validation-request.mobile';

/*********************
Generic
*********************/

@import 'snap/snap.mobile';
