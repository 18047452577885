@import 'about-us';

.page.about-us {
  &.rent {
    p {
      a {
        color: black;
        text-decoration: underline;
      }
    }
  }
  .container {
    .unique-place-list-about {
      a {
        color: $black;
      }
    }
    .row-title-about {
      padding-top: 175px;
      text-align: center;
      background-color: blue;
      color: $white;
      .first-title-about {
        margin: 0 auto 135px;
      }
      .subtitle-about {
        display: inline-block;
        margin-bottom: 20px;
        height: 35px;
        background: red;
      }
    }

    .innovative-service-title-about {
      margin: 40px auto;
      text-align: center;
      .title-innovative-service-title-about {
        color: $yellow;
        &.title-innovative-service-title-rent {
          padding: 10px;
        }
      }
    }

    .unique-places-about.places-rent-up {
      padding: 20px;
      p {
        text-align: justify;
        margin-bottom: 20px;
      }
    }

    .innovative-service-content-about {
      padding: 0 6%;
      .picture-1-about {
        padding: 0 13%;
        width: 100%;
        img {
          width: 100%;
        }
      }

      .text-innovative-service-content-about {
        padding-top: 23px;

        .title-text-innovative-service-content-about {
          margin-bottom: 31px;
        }

        strong {
          font-weight: 900;
        }
        &.text-innovative-service-content-rent {
          p {
            margin-bottom: 20px;
            text-align: justify;
          }
        }
      }
    }

    .unique-place-content-about {
      padding: 63px 6% 0;

      .title-strat-about {
        margin-bottom: 31px;
        text-align: center;
        color: $yellow;
      }

      .title-text-innovative-service-content-about {
        margin-bottom: 31px;
      }
      .text-unique-place-content-about {
        &.text-unique-place-content-rent {
          p {
            margin-bottom: 20px;
            text-align: justify;
          }
        }
      }
    }

    .unique-place-list-about {
      padding: 25px 6% 0;
      text-align: center;

      .image-unique-place-list-about {
        width: 100%;

        .list-unique-place-list-about {
          display: inline-block;
          text-align: left;
          width: 100%;

          .list-element-unique-place-list-about {
            width: calc(50% - 6px);
            display: inline-block;
            text-align: center;

            &:nth-child(2n + 1) {
              margin-right: 9px;
            }

            .image-list {
              position: relative;
              padding-bottom: 110%;
              overflow: hidden;

              img {
                position: absolute;
                max-width: 100%;
                min-width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .subtitle-images {
              margin-top: 10px;
              margin-bottom: 21px;
            }
          }
        }
      }

      .button-unique-place-list-about {
        margin: 16px auto 90px;
      }
    }

    .custom-design-about {
      padding: 50px 6% 0;
      background: $light-beige;
      text-align: center;

      .title-strat-about {
        color: $yellow;
        margin-bottom: 30px;
      }

      .picture-2-about {
        position: relative;
        margin-bottom: 50px;
        padding: 0 15%;
        width: 100%;

        &::after {
          position: absolute;
          content: '';
          height: 23px;
          width: 46%;
          left: 50%;
          transform: translateX(-50%);
          background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
          background-size: 50% auto;
          bottom: -20px;
        }
        img {
          width: 100%;
        }
      }

      .column-text-custom-design-about {
        .title-text-custom-design-about {
          text-align: left;
          margin-bottom: 31px;
        }

        .text-content-custom-design-about {
          text-align: left;
        }
      }

      .button-custom-design-about {
        margin: 35px auto 0;
      }
      &.custom-design-rent {
        p {
          text-align: justify;
          margin-bottom: 20px;
        }
      }
    }

    .dedicated-support-about {
      padding: 60px 6% 55px;
      text-align: center;
      background: $light-beige;

      .title-strat-about {
        color: $yellow;
        margin-bottom: 30px;
      }

      .picture-3-about {
        padding: 0 13%;
        width: 100%;
        margin-bottom: 50px;
        img {
          width: 100%;
        }
      }

      .title-text-dedicated-support-about {
        margin-bottom: 31px;
      }

      .text-content-dedicated-support-about {
        text-align: left;

        strong {
          font-weight: 900;
        }
      }

      .image-place-in-text-place-about {
        margin-top: 30px;

        .list-dedicated-support-about {
          display: inline-block;

          .list-element-dedicated-support-about {
            padding-left: 6%;
            padding-right: 6%;
            display: inline-block;
            max-width: 49%;

            img {
              width: 100%;
            }
          }
        }

        &.single {
          .list-dedicated-support-about {
            .list-element-dedicated-support-about {
              max-width: 100%;
            }
          }
        }
      }
      &.dedicated-support-about {
        p {
          margin-bottom: 20px;
          text-align: justify;
        }
      }
    }

    .new-concept-about {
      padding: 75px 6% 85px;
      text-align: center;
      background-image: url('../../../../../../web/medias/img/mobile/pages/about-us/about-bg.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 440px 440px;

      .title-text-new-concept-about {
        margin-bottom: 31px;
      }

      .button-new-concept-about {
        margin-top: 25px;
      }
    }
  }
}
