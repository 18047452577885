@import './layout';

body {
  visibility: visible;

  strong {
    font-weight: 900;
  }

  // overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  main {
    z-index: inherit;

    .page {
      &.show {
        padding-bottom: 80px;
      }
    }
  }
}
