@import 'guest';

.page.user.guest {
  padding-top: 50px;
  background: none;

  .title-2-guest {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .big-title-guest {
    font-size: 1.7rem;
  }
  .container {
    padding: 45px 7%;
    .text-content {
      text-align: left;
      .headline {
        font-size: 1.1rem;
        br {
          display: none;
        }
      }
    }
    .guest-account {
      form {
        flex-wrap: nowrap;
        flex-direction: column;

        .container-btn {
          width: 100%;
          .continue {
            span {
              font-size: 1rem;
            }
          }
          .link-login {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
