@import 'select-checkbox';

.ct--select-checkbox .filter-select-choice .select-choice,
.ct--select-checkbox .filter-select-choice .validate-choice {
  padding: 10px 15px;
}
.ct--select-checkbox .filter-choice .response-filter,
.ct--select-checkbox .filter-select-choice .select-choice .label {
  font-family: $rawline;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: none;
}
.ct--select-checkbox .filter-choice .response-filter {
  font-weight: bold;
  display: block;
  height: 100%;
  line-height: 35px;
}
