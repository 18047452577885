@import 'item-result';

.ct.ct--item-result {
  border: none;
  margin-bottom: 15px;

  &:after {
    background-color: #ccc;
    content: '';
    display: block;
    height: 1px;
    margin: 10px auto 30px;
    width: 25%;
  }

  .ui-slider {
    opacity: 1;
  }

  &.selected {
    margin-bottom: 20px;
    &:after {
      margin: 0;
    }
  }

  &.dashboard {
    .remove-item {
      opacity: 1;
    }
  }

  &.place,
  &.menu,
  &.cocktail {
    .about {
      padding-bottom: 20px;

      .headline {
        margin-bottom: 5px;
      }
    }
  }

  &.drink,
  &.glass,
  &.furniture,
  &.staff {
    .product {
      padding: 15px;
      border: none;

      .cols {
        .pic {
          width: 40%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .right {
          width: 60%;
        }
      }
    }

    .about {
      padding: 25px 10px;

      .cols {
        .col {
          &.add {
            .time {
              margin-right: 5px;
            }

            .btn-add-card {
              width: 82px;
              height: 30px;
              padding: 0;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .actions {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px 20px;

    a,
    button,
    .ct--button {
      font-family: $montserrat;
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
      text-transform: uppercase;
    }

    a,
    .ct--button {
      background-color: $light-duck;
      color: $white;
    }
  }

  .choose-option {
    display: flex;
    border: 1px solid #ccc;
    width: 95px;
    height: 30px;
    overflow: hidden;
    background: $white;
    margin-right: 10px;

    select {
      padding: 5px 0 5px 10px;
      width: calc(100% - 15px);
      height: 100%;
      border: none;
      box-shadow: none;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;

      &:focus {
        outline: none;
      }
    }

    .cursor {
      display: flex;
      width: 15px;
      height: 100%;
      align-items: center;
      justify-content: center;

      .icon {
        width: 8px;
        height: 5px;
      }
    }
  }
}

.ct.ct--item-result-custom {
  &:after {
    background-color: #ccc;
    content: '';
    display: block;
    height: 1px;
    margin: 10px auto 30px;
    width: 25%;
  }
  .picture {
    aspect-ratio: 508/366;
    background: url('./../../../img/pages/location-de-salle/paris/app-my-snapevent.jpg')
      center center no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(55, 55, 55, 0.55);
    }
    .picture-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      span {
        margin: 0 auto;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.15) 1px 4px 4px;
        z-index: 10;
        color: $white;
        @extend .vanitas-bold;
        font-size: 4rem;
        line-height: 1.2;
        display: block;
        small {
          display: block;
          font-size: 2.5rem;
          margin-top: 12px;
        }
      }
    }
    .picture-link {
      position: absolute;
      bottom: 20px;
      right: 20px;
      text-transform: uppercase;
      color: $yellow;
      font-family: $montserrat;
      .arrow {
        color: $yellow;
        fill: $yellow;
        font-size: 2rem;
        margin-left: 15px;
        position: relative;
        top: 5px;
      }
    }
    svg.svg-jo {
      display: block;
      width: 80px;
      aspect-ratio: 1020/495;
      position: absolute;
      height: auto;
      top: 20px;
      right: 20px;
      fill: #fff;
    }
  }
  .content {
    padding: 20px;
    color: $black;
  }
}
