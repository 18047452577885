@import 'double-col';

.ct.ct--double-col {
  margin-bottom: 60px;
  .container {
    padding: 0 6%;
    .cols {
      .headline {
        margin-bottom: 40px;
        display: block;
        text-align: center;
      }
      .col {
        .inner {
          // text-align: center;
          .title-double-col,
          .content-double-col {
            text-align: left;
          }
        }
        &.col-content {
          margin-top: 15px;
          .inner {
            .content-double-col {
              margin: 10px 0 20px;
            }
            .section-title-double-col {
              margin-top: -5px;
              margin-bottom: 0;
              line-height: 1.2;
              font-size: 1.4rem;
              font-family: $rawline;
              font-weight: normal;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
