@import 'homepage';
@import 'homepage.searchbar.mobile';

.page.homepage-index {
  .section-header-wraper {
    position: relative;

    .section-header {
      .contents {
        height: 85%;

        .content {
          padding: 0 7%;

          .title-header {
            margin-bottom: 30px;

            a {
              color: $white;
            }
          }

          .cta-container {
            padding: 0 25px;

            .cta-button {
              cursor: pointer;
              @include ct--button-action;
              position: relative;
              display: flex;
              width: 100%;
              padding: 0 20px;
              background: $yellow;
              height: 45px;
              border: 0;
              align-items: center;
              justify-content: space-between;
              @extend .montserrat-light;
              font-size: 1.3rem;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: $bobby-brown;

              .icon {
                width: 12px;
                height: 12px;
                fill: $bobby-brown;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }

      .pictures {
        $images: 1, 2, 3, 4, 5, 6, 7;
        @each $image in $images {
          &.header-#{$image} {
            background-image: url('./../../../img/home/header/header-#{$image}.mobile.blurry.jpg');
            .pic {
              background-image: url('./../../../img/home/header/header-#{$image}.mobile.jpg');
            }
          }
        }
      }
    }
  }

  .section-trends {
    // override here
  }

  .section-key-figures {
    padding: 40px 0;
    margin-bottom: 60px;
  }

  .logos-slider {
    margin: -30px 0 40px;
  }

  .section-more {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 10px;

    .ct.ct--double-col-slider {
      .slider {
        .inner {
          background-color: white !important;
        }

        ul.bullets {
          li {
            &.active {
              background-color: $black !important;
            }

            background-color: $dark-beige !important;
          }
        }
      }
    }

    .ct.ct--button.arrow.cta-1 {
      width: 100%;
    }
  }

  .section-zoom {
    position: relative;
    width: 100%;

    .wrapper-section-zoom {
      padding: 70px 0;

      &:before {
        width: 100%;
        height: 100%;
        left: 0%;
        background-size: 50% auto;
      }

      .title-zoom {
        margin-bottom: 40px;
      }
    }
  }

  #customers {
    top: -90px;
  }

  .ct--double-col-slider-companies .ct.ct--button {
    margin-bottom: 60px;
    margin-top: -20px;
  }
}
