@import 'events';

.page.events {
  $font-size: 16px;

  .container {
    padding: 20px;
  }

  .introduction {
    margin: 0 0 50px;

    p {
      font-size: $font-size;
    }
  }

  .section {
    margin-bottom: 50px;

    .section-header {
      flex-direction: column;
      margin-bottom: 0;

      .section-title {
        font-size: 4rem;
        margin-top: 20px;
        width: 100%;
      }

      .section-title-decorator {
        padding: 15px 0;
        width: 100%;

        .images {
          grid-gap: 15px;
        }
      }

      &.section-header-inversed {
        flex-direction: column;

        .section-title-decorator {
          &:before {
            left: initial;
            right: -20px;
          }
        }
      }
    }

    .section-content {
      margin: 10px 0 0;
      text-align: center;

      ul {
        li {
          display: block;
          margin: 15px 0;
          width: 100%;

          a {
            font-size: $font-size;
          }
        }
      }
    }
  }
}
