@import 'charte-qualite';

.page.charte-qualite {
  .ct--header {
    .header {
      .first-title {
        font-size: 4rem;
        padding: 0 20px;

        small {
          font-size: 3rem;
        }
      }
    }
  }

  .container {
    padding: 0 20px;
  }

  .intro-row {
    margin: 40px 0;
    padding: 0;

    h2 {
      font-size: 4rem;
      margin-bottom: 40px;
    }

    p {
      font-size: 1.5rem;
    }
  }

  .numbers-row {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    padding: 0;

    .c4 {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .number {
      font-size: 2rem;
    }

    .number-desc {
      font-size: 1.5rem;
    }
  }

  .reference-row-rent,
  .service-row-rent,
  .reference-row-rent,
  .partner-row,
  .new-concept-rent {
    padding: 0;
    text-align: left;
  }

  .button-wrapper {
    margin-top: 20px;
    text-align: left;
  }

  .reference-row-rent {
    .left-reference-column {
      margin-bottom: 20px;
    }
  }

  .service-row-rent {
    margin-bottom: 30px;

    .content-place-service {
      display: flex;
      flex-direction: column-reverse;

      .right-service-column {
        margin-bottom: 20px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .partner-row {
    margin-left: -20px;
    margin-top: 40px;
    padding: 30px 20px;
    width: 100vw;

    .partner-row-content {
      .partner-row-content-right {
        display: none;
      }
    }
  }

  .partner-row-title,
  .animations-row-title {
    @extend .keyword;
    display: block;
    margin: 0 auto 40px;
    text-align: center;
  }

  .partner-row,
  .animations-row-rent {
    .button-wrapper {
      text-align: center;
    }
  }

  .animations-row-rent {
    .animations-row-title {
      margin-top: 40px;
    }

    .subtitle {
      text-align: center;
    }
  }

  .new-concept-rent {
    margin: 50px 0;
    text-align: center;
    .text-content-new-concept-rent {
      margin: 25px 0;
    }
  }
}
