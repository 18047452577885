@import 'zones';

.ct--zones {
  .zones-wrapper {
    .ct--zone-item {
      .about {
        padding-bottom: 15px;
      }
    }
  }
}
