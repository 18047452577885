.input-text,
input[type='text'] {
  @extend .rawline-regular;
  display: block;
  font-size: 1.1rem;
  height: 35px;
  padding: 0 20px;
  background-color: $input-bg;
  border: 0;
  color: $black;
  &.error {
    border: 2px solid $red;
    color: $red;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .input-text,
  input[type='text'] {
    font-size: 16px;
  }
}

.button-radio {
  display: block;
  margin-bottom: 3px;

  .checkbox {
    vertical-align: middle;
    background-color: $white;
    border: 1px solid $dark-beige;
    margin-right: 1px;
  }

  .checkmark {
    vertical-align: middle;
  }
}

.label,
label {
  color: $yellow;
  font-weight: 900;
  margin-bottom: 8px;
  display: block;
  &.error {
    color: $red;
  }
}
