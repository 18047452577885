@import 'articles';

.section-snapmag {
  padding: 30px 0 100px;
  .last-news {
    .container {
      .inner {
        padding: 0 6%;
        .about-last-news {
          padding: 0;
          margin-bottom: 30px;
        }
        .title-last-news {
          margin-bottom: 40px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}
