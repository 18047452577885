@import 'tos';

.page.tos {
  padding: 40px 6% 0;
  background-image: url('../../../../../../web/medias/img/mobile/pages/about-us/about-bg.png');
  background-position: 200px -70px;
  background-size: 340px;
  background-repeat: no-repeat;
  label.button-checkbox {
    color: inherit;
    margin-bottom: 20px;
  }
}
