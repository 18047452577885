@import 'selection';

// buttons

.add-to-selection,
.remove-from-selection {
  font-size: 12px;
}

// show ui

.selection-show-helper {
  margin: 0;
  width: 100%;
  .add-to-selection,
  .remove-from-selection {
    width: 100%;
  }
}
