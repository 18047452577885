@import 'transmission-request';
.page.user.transmission-request {
  display: block;
  background-position: 200% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  min-height: auto;
  .top-bar-return {
    .back {
      margin-right: 15px;
    }
    .title-top-bar {
      font-size: 1.5rem;
    }
  }
  .ct.ct--breadcrumb {
    margin: 10px 0 20px;
  }
  .content-transmission {
    padding: 0 5%;
    .title-content {
      font-size: 1.7rem;
      line-height: 17px;
      br {
        display: none;
      }
    }
    .text-content {
      font-size: 1.1rem;
      line-height: 15px;
      margin: 25px 0 45px;
    }
    .btn {
      padding: 10px 40px;
      min-height: 50px;
      white-space: initial;
      line-height: 1.5;
      height: auto;
    }
  }
}
