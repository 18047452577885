@import 'select-slider';

.ct.ct--select-slider {
  .filter-select-choice .slider-choice .text-1,
  .filter-choice .response-filter {
    text-transform: none;
    font-family: $rawline;
    font-size: 1.2rem !important;
    font-weight: bold;
    text-transform: none;
  }
  .filter-select-choice .slider-choice {
    .range-slider {
      margin: 0 0 10px;
    }
    .text-1 {
      display: none;
    }
  }
}
