@import 'zones';

#page-zones {
  padding-top: 50px;

  .section-arrondissements,
  .section-departements {
    padding: 30px 0;
    .container {
      .inner {
        padding: 0 6%;
        .zones-wrapper {
          .ct--zone-item {
            .about {
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .section-departements {
    padding: 40px 0;
  }

  h1,
  h2 {
    font-size: 4rem;
    margin-bottom: 40px;
  }
}
