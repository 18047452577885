@import 'login_registration';

.social-connect {
  display: block;
}

.social-connect-item {
  max-width: 100%;
  display: block;
  margin-bottom: 10px;
}
