@import 'double-col-slider-companies';

.ct.ct--double-col-slider-companies {
  margin-bottom: 0;

  .container {
    padding: 0 6%;

    .cols {
      display: flex;
      flex-direction: column-reverse;

      .col-content {
        margin-top: 20px;
      }

      .col {
        &.col-picture {
          .slider {
            margin-top: 20px !important;

            &:after {
              display: none;
            }

            .bullets {
              li {
                width: 10px;
                height: 10px;
              }
            }

            .inner {
              .slide {
                padding: 0;
                .row {
                  .cell {
                    padding: 20px;
                    img {
                      height: auto;
                      display: block;
                    }
                  }
                }
              }
            }
          }

          .headline {
            position: relative;
            left: auto;
            margin-top: 10px;
            margin-bottom: 40px;
            display: block;
            text-align: center;
          }
        }

        &.col-content {
          .inner {
            .section-title-double-col {
              margin-top: -20px;
              margin-bottom: -10px;
              line-height: 1.2;
              font-size: 1.4rem;
              font-family: $rawline;
              font-weight: normal;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
