@import 'key-figures';

.ct.ct--key-figures {
  padding: 1px 0;
  .container {
    .title-key-figures {
      line-height: 1.1;
    }
    .inner {
      .figures {
        margin: 30px 0;
        .figure {
          margin-bottom: 40px;
          .number-figure {
            height: 50px;
            margin-bottom: 20px;
          }
          .content-figure {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
