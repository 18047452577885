@import 'rent';

.page.rent {
  .ct--header {
    .header {
      .big-title-1 {
        font-size: 5rem;
      }
    }
  }

  .subtitle {
    margin-bottom: 30px;
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
  }

  // formulaire

  .form-row-rent {
    padding: 0 6%;
    text-align: center;

    #formulaire {
      position: relative;
      top: -80px;
    }

    .form-row-rent-title {
      color: $yellow;
      margin: 60px auto 40px;
      display: block;
    }

    .form-row-rent-content {
      .form-row-rent-content-left-column {
        margin-bottom: 40px;
        vertical-align: top;
        .button-wrapper {
          margin-top: 20px;
        }
      }

      .form-row-rent-content-right-column {
        vertical-align: top;
      }
    }
  }

  .reference-row-rent {
    padding: 0 6%;
    text-align: center;

    #referencement {
      position: relative;
      top: -80px;
    }

    .title-reference-row {
      color: $yellow;
      margin: 60px auto 40px;
      display: block;
    }

    .content-place-reference-row {
      text-align: left;

      .sup-reference-content {
        width: 100%;
        margin-bottom: 31px;
        padding: 0 6%;
      }

      .sub-reference-content {
        .reference-text {
          margin-bottom: 20px;
        }

        .reference-list {
          .reference-elements-list {
            margin-left: 5px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: -8px;
              width: 3px;
              height: 1px;
              background-color: $black;
              top: 8px;
            }
          }
        }
      }
    }
  }

  .service-row-rent {
    padding: 0 6%;
    margin-top: 63px;
    text-align: center;

    .title-service-row {
      color: $yellow;
      margin: 60px auto 30px;
      display: block;
    }

    .content-place-service {
      .sup-service-row {
        text-align: left;

        .service-text {
          margin-bottom: 31px;

          span {
            font-weight: 900;
          }
        }
      }

      .sub-service-row {
        width: 100%;
        margin-bottom: 35px;

        img {
          width: 100%;
        }
      }
    }
  }

  .organisation-row-rent {
    padding: 40px 6% 63px;
    margin-top: 33px;
    background-color: $light-beige;
    text-align: center;

    .title-organisation-row {
      color: $yellow;
      margin: 0 auto 40px;
      display: block;
    }

    .content-place-organisation {
      text-align: left;

      .sup-organisation-row {
        padding: 0 13%;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .sub-organisation-row {
        .title-sub-organisation {
          margin-top: 31px;
        }

        .organisation-text {
          margin-bottom: 21px;

          span {
            font-weight: 900;
          }
        }

        .organisation-list {
          margin-bottom: 21px;

          .organisation-list-elements {
            margin-left: 5px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: -8px;
              width: 3px;
              height: 1px;
              background-color: $black;
              top: 8px;
            }
          }
        }
      }
    }
  }

  .security-row-rent {
    padding: 0 6%;
    text-align: center;

    .title-security-row {
      color: $yellow;
      margin: 60px auto 40px;
      display: block;
    }

    .content-place-security {
      text-align: left;

      .sup-security-row {
        margin-bottom: 0;
      }

      .button-security-rent {
        margin: 0 auto 40px;
        display: inline-block;
        width: 100%;
      }

      .sub-security-row {
        margin-bottom: 0;

        .image-place-security {
          width: 100%;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: -23px;
            height: 25px;
            width: 50%;
            left: 50%;
            transform: translateX(-50%);
            background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .new-concept-rent {
    text-align: center;
    background-image: url('../../../../../../web/medias/img/mobile/pages/about-us/about-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 430px;
    padding: 150px 6% 135px;

    .title-text-new-concept-rent {
      margin: 0 auto 15px;
      max-width: 283px;
    }

    .text-content-new-concept-rent {
      margin: 0 auto;
      max-width: 96%;
    }

    .button-new-concept-place {
      margin-top: 15px;
    }
  }
}
