@import 'validation-request';
body {
  main {
    height: auto;
    .page.user.validation-request {
      position: inherit;
      padding: 50px 0 0;
      background-position: 150% 50%;
      background-repeat: no-repeat;
      background-size: 70%;
      height: auto;
      .validation {
        width: 100%;
        height: auto;
        .ct.ct--breadcrumb {
          margin: 0;
          .inner {
            padding: 15px 7%;
          }
        }
        .content-validation {
          padding: 15px 6% 45px;
          max-width: 425px;
          .title-content {
            font-size: 1.7rem;
            line-height: 17px;
            br {
              display: none;
            }
          }
          .text-content {
            font-size: 1.1rem;
            line-height: 15px;
            margin: 25px 0 35px;
            br {
              display: none;
            }
            &:nth-child(4) {
              margin-top: 10px;
            }
          }
          .subtitle {
            font-size: 1.4rem;
          }
          .ct--button {
            margin: 0 auto;
            display: flex;
            height: 35px;
            width: 200px;
          }
        }
      }
    }
  }
}
