@import 'payment';

.page.payment {
  h2 {
    padding-left: 20px;
  }
  .payment-choice {
    padding: 20px;
    p.text-1.offline-spacer {
      margin-bottom: 20px;
    }

    .cta {
      min-width: unset;
      width: 100%;
      justify-content: center;
    }
  }
}
