@import '../../variables/fonts';

.category-slider-wrapper {
  margin: 0;
  padding: 0 0 20px;

  .category-slider-title {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .category-slider {
    .category-slide {
      .category-slide-link-wrapper {
        background: transparent center center no-repeat;
        background-size: cover;
        color: #fff;
        text-align: center;
        height: 250px;
        display: block;
        padding: 75px 0;

        &::before {
          background-color: rgba(55, 55, 55, 0.55);
          content: '';
          display: block;
          flex: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          transition: background-color 0.2s ease;
        }

        &:hover:before {
          background-color: rgba(55, 55, 55, 0.7);
        }

        .category-slide-title {
          z-index: 2;
          position: relative;
          height: 100%;
          padding: 5px 0;
          font-family: $rawline;

          hr {
            width: 15px;
            margin-bottom: 15px;
            margin-top: 15px;
          }

          .over-title {
            font-size: 16px;
          }

          .under-title {
            font-size: 20px;
            font-weight: bold;
            font-family: $vanitas;
          }
        }
      }
    }
  }
}
