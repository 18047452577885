@import 'trends';

.section-trends {
  .container {
    .title-trends {
      margin-bottom: 35px;
    }

    .inner {
      .slider-trends {
        overflow: hidden;

        .slider {
          width: calc(100vw * 3);
          display: flex;

          .pic {
            position: relative;
            width: calc(100vw - 80px);
            background-size: cover;
            background-position: center center;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            &:before {
              position: relative;
              content: '';
              padding-bottom: 100%;
              height: 0;
              width: 100%;
              display: block;
            }

            a {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: 5;
              color: $white;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;

              .title-picture {
                font-size: 2rem;
                line-height: 1.5;
                text-align: center;
                text-shadow: 0 1px 3px #000000cc;
              }
            }

            &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.3);
              z-index: 2;
            }
          }
        }
      }

      .content-trends {
        padding: 30px 6% 70px;

        .text-content {
          margin: 20px 0;
        }
      }
    }
  }
}
