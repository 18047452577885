@import 'header';

.ct--header {
  &.full-header {
    height: 100vh;
  }

  &.min-header {
    height: calc(100vh - 120px);
  }

  .header {
    position: relative;
    padding-top: 175px;
    text-align: center;
    background-color: none;
    background-size: cover;
    background-position: center center;
    color: $white;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(55, 55, 55, 0.7);
      z-index: 5;
    }

    .first-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      text-shadow: rgba(0, 0, 0, 0.15) 1px 4px 4px;
      z-index: 6;
      padding: 0 20px;
      font-size: 5rem;

      small {
        display: block;
        margin-top: 12px;
        font-size: 4rem;
      }
    }

    .cta-wrapper {
      position: absolute;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      width: 100%;
    }

    .subtitle-header {
      position: absolute;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
    }

    .wrapper-icon-header {
      position: absolute;
      bottom: 0;
      width: 100%;

      .subtitle-header {
        display: inline-block;
        margin-bottom: 19px;
        background: none;
      }
      .icon-container-header {
        cursor: pointer;
        margin-bottom: 35px;
        @keyframes arrow-down-move {
          0% {
            transform: translateY(5px);
          }
          15% {
            transform: translateY(0);
          }
          30% {
            transform: translateY(5px);
          }
        }
        .icon-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 10px;
          margin: 0 auto;
          transition: transform 0.4s $ease-out-cubic;
          animation: arrow-down-move 1.5s infinite linear alternate;

          .header-arrow-down-sup {
            display: inline-flex;
            align-items: center;
            background: transparent;

            .icon {
              width: 9px;
              height: 7px;
              fill: $white;
              color: $white;
            }
          }

          .header-arrow-down-sub {
            display: inline-flex;
            align-items: center;
            background: transparent;

            .icon {
              width: 9px;
              height: 7px;
              fill: $white;
              color: $white;
            }
          }
        }
      }
    }
  }
}
